import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";

import { Container, Box, Button, IconButton } from "@mui/material";
import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import { ModalWrapper } from "../Modal";
import InfoIcon from '@mui/icons-material/Info';

import checked from '../../assets/images/checked.png';
import Footer from "../Footer";

const DriverSupportDetailsGrid = ({ data }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({ title: '', show: false });
    const filteredData = filterByValue(data, search);
    const sizeCol = 200;

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    }

    const gridProps = {
        getRowId: (row) => row.rideID,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'rideID',
            headerName: 'Ride ID',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'date',
            headerName: 'Data',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'price',
            headerName: 'Preço',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'currency',
            headerName: 'Moeda',
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'originAddress',
            headerName: 'Endereço de origem',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'destinationAddress',
            headerName: 'Endereço de destino',
            headerAlign: 'center',
            align: 'center',
            minWidth: 130,
            flex: 1
        },
        {
            field: 'receipt',
            headerName: 'Recibo ID',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'completed',
            headerName: 'Realizada',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'report',
            headerName: 'Ver Relatório',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleDetailsClick(params.row)}>
                    <InfoIcon />
                </IconButton>
            ),
        },
    ];

    const handleDetailsClick = (row) => {
        console.log('Detalhes para:', row);
    };

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={10} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{
                    rideID: 'Ride ID',
                    date: 'Data',
                    price: 'Preço',
                    currency: 'Moeda',
                    originAddress: 'Endereço de origem',
                    destinationAddress: 'Endereço de destino',
                    receipt: 'Receipt ID',
                    completed: 'Realizada',
                    report: 'Ver Relatório'
                }} />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
            <Footer />

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(prev => ({ ...prev, show: false }))}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(prev => ({ ...prev, show: false }))}>Ok</Button>
                </Box>
            </ModalWrapper>
        </Container>
    )
}

export default DriverSupportDetailsGrid;
