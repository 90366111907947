import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { GetCompanyWithConfiguration } from '../store/_Entities/Company';

import {
    Box,
    Button,
    CircularProgress,
    Modal,
    Typography
} from '@mui/material';

const CompanyModal = ({ companyID, showModal, setShowModal }) => {
    const { company } = useSelector((state) => state.entities);
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyID !== 0) {
            dispatch(GetCompanyWithConfiguration(companyID));
        }
    }, [companyID]);

    const modalContentStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 600,
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        border: 'none',
        p: 4
    };

    const companyContent = [
        {
            title: 'Nome da Empresa',
            content: company.selectedCompany?.name || 'N/A'
        },
        {
            title: 'Nome Fantasia',
            content: company.selectedCompany?.giveName || 'N/A'
        },
        { title: 'CNPJ', content: company.selectedCompany?.document || 'N/A' }
    ];

    const companyAddress = [
        {
            title: 'Logradouro',
            content: company.selectedCompany?.street || 'N/A'
        },
        { title: 'Número', content: company.selectedCompany?.number || 'N/A' },
        { title: 'Cidade', content: company.selectedCompany?.city || 'N/A' }
    ];

    const companyConfigurations =
        company.selectedCompany?.companyConfigurations?.map((item) => (
            <Box
                key={item.companyConfigurationID}
                px={1}
                sx={{
                    boxShadow: '0px 0px 4px 0px #00000040',
                    borderRadius: 2,
                    width: 'auto',
                    textAlign: 'center',
                    fontWeight: 'medium',
                    display: 'inline-block'
                }}
            >
                {item.description}
            </Box>
        ));

    const ModalSection = ({ title, content }) => {
        return (
            <>
                <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {content.map((item, index) => (
                        <Box key={index}>
                            <Typography sx={{ fontWeight: 'medium' }}>
                                {item.title}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 5 }}>
                                {item.content}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </>
        );
    };
    if (companyID === 0) return null;

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-company" sx={modalContentStyle}>
                {company.loading ? (
                    <Box display={'flex'} justifyContent={'center'}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box
                            height={500}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3
                            }}
                        >
                            <ModalSection
                                title="Informações da Empresa"
                                content={companyContent}
                            />
                            <ModalSection
                                title="Endereço"
                                content={companyAddress}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 3
                                }}
                            >
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        Configurações Ativas
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 2,
                                        maxHeight: 200,
                                        overflowY: 'auto',
                                        padding: 1
                                    }}
                                >
                                    {companyConfigurations}
                                </Box>
                            </Box>
                        </Box>
                        <Box marginTop={2}>
                            <Button
                                variant="contained"
                                sx={{ width: 200 }}
                                onClick={() => setShowModal(false)}
                            >
                                Ok
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default CompanyModal;
