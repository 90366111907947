import { useState } from 'react';
import { Container, Grid, Box, Button } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import Footer from '../../components/Footer';
import ResponsiveAppBar from '../../components/NavBar';
import InvoicingConfigGrid from '../../components/grids/InvoicingConfigGrid';
import CreateInvoice from '../../components/forms/Invoicing/CreateInvoince';
import HeaderSelect from '../../components/HeaderSelect';

const mockInvoices = [
    {
        userID: '001',
        username: 'Invoice1',
        companyID: 1,
        createdAt: '2024-10-01',
        isActive: 'Sim',
        invoiceClosingDay: '30',
        invoiceDueDays: '5',
        invoiceSuffix: 'INV',
        purchaseOrder: 'PO12345',
        debitNote: 'sim',
        csvRides: 'sim',
        csvSavings: 'Não',
        csvKmUsers: 'Não',
        csvExpenUsers: 'Não'
    },
    {
        userID: '002',
        username: 'Invoice2',
        companyID: 2,
        createdAt: '2024-09-15',
        isActive: 'Não',
        invoiceClosingDay: '15',
        invoiceDueDays: '10',
        invoiceSuffix: 'INV2',
        purchaseOrder: 'PO67890',
        debitNote: 'sim',
        csvRides: 'sim',
        csvSavings: 'Não',
        csvKmUsers: 'Não',
        csvExpenUsers: 'Não'
    }
];

const mockCompanies = [
    { companyID: 1, name: 'Empresa A' },
    { companyID: 2, name: 'Empresa B' },
    { companyID: 3, name: 'Empresa C' }
];

const invoicesWithId = mockInvoices.map((invoice) => ({
    ...invoice,
    id: invoice.userID
}));

const InvoicingConfig = () => {
    const [invoices, setInvoices] = useState(invoicesWithId);
    const [filteredInvoices, setFilteredInvoices] = useState(invoicesWithId);
    const [selectedCompany, setSelectedCompany] = useState('all');
    const [showForm, setShowForm] = useState(false);
    const methods = useForm();

    const filterInvoicesByCompany = (companyID) => {
        if (companyID === 'all') {
            setFilteredInvoices(invoices);
        } else {
            const filtered = invoices.filter(
                (invoice) => String(invoice.companyID) === String(companyID)
            );
            setFilteredInvoices(filtered);
        }
    };

    const handleAddInvoice = (data) => {
        const newInvoice = { ...data, id: String(invoices.length + 1) };
        setInvoices((prevInvoices) => [...prevInvoices, newInvoice]);
        setShowForm(false);
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        py={1}
                        sx={{ marginBottom: '10px', borderBottom: '1px solid black' }}
                    >
                        <h2>Configuração de Faturamento</h2>
                        {!showForm && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'end'
                                }}
                            >
                                <HeaderSelect
                                    label="Empresas"
                                    defaultValue="all"
                                    onChange={(event) => {
                                        setSelectedCompany(event.target.value);
                                        filterInvoicesByCompany(event.target.value);
                                    }}
                                    menuItems={[
                                        { label: 'Todas', value: 'all' },
                                        ...mockCompanies.map((company) => ({
                                            label: company.name,
                                            value: company.companyID
                                        }))
                                    ]}
                                />
                                <Box display="flex" gap={2}>
                                    <Button variant="contained" onClick={() => setShowForm(true)}>
                                        Nova Configuração de Fatura
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {showForm ? (
                            <FormProvider {...methods}>
                                <CreateInvoice onSubmit={methods.handleSubmit(handleAddInvoice)} />
                            </FormProvider>
                        ) : (
                            <InvoicingConfigGrid data={filteredInvoices} loading={false} />
                        )}
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default InvoicingConfig;
