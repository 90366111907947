// Redux
import { combineReducers } from 'redux';

// Slices
import UserReducer from './User';
import CompanyReducer from './Company';
import SystemAdminReducer from './SystemAdmin';
import ProductAdminReducer from './ProductAdmin';


export default combineReducers({
    user: UserReducer,
    company:CompanyReducer,
    systemAdmin:SystemAdminReducer,
    ProductAdmin:ProductAdminReducer
});
