import { useState } from "react";
import { Container, Box, Button, IconButton } from "@mui/material";
import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import { ModalWrapper } from "../Modal";
import InfoIcon from '@mui/icons-material/Info';
import { filterByValue } from "../../utils/filterGrid";

import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Footer from "../Footer";
import checked from '../../assets/images/checked.png';

const SupportGrid = ({ data }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({ title: '', show: false });
    
    const filteredData = filterByValue(data, search);

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    }

    const gridProps = {
        getRowId: (row) => row.wexpID,
        disableSelectionOnClick: true,
    };

    const columns = [
        { field: 'wexpID', headerName: 'WExp ID', headerAlign: 'center', align: 'center', minWidth: 100, flex: 1 },
        { field: 'registration', headerName: 'Matrícula', headerAlign: 'center', align: 'center', minWidth: 120, flex: 1 },
        { field: 'name', headerName: 'Nome', headerAlign: 'center', align: 'center', minWidth: 150, flex: 1 },
        { field: 'email', headerName: 'E-mail', headerAlign: 'center', align: 'center', minWidth: 200, flex: 1 },
        { field: 'status', headerName: 'Situação', headerAlign: 'center', align: 'center', minWidth: 100, flex: 1 },
        { field: 'phone', headerName: 'Telefone', headerAlign: 'center', align: 'center', minWidth: 130, flex: 1 },
        { field: 'costCenter', headerName: 'Centro de Custo', headerAlign: 'center', align: 'center', minWidth: 150, flex: 1 },
        { field: 'company', headerName: 'Empresa', headerAlign: 'center', align: 'center', minWidth: 150, flex: 1 },
        { field: 'date', headerName: 'Data', headerAlign: 'center', align: 'center', minWidth: 120, flex: 1 },
        {
            field: 'inactivate',
            headerName: 'Inativar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleInactivate(params.row)}>
                    <BlockIcon color="error" />
                </IconButton>
            ),
        },
        {
            field: 'reactivate',
            headerName: 'Reativar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleReactivate(params.row)}>
                    <CheckCircleIcon color="primary" />
                </IconButton>
            ),
        },
        {
            field: 'resend',
            headerName: 'Reenviar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleResend(params.row)}>
                    <SendIcon color="secondary" />
                </IconButton>
            ),
        },
        {
            field: 'resendWappa',
            headerName: 'Reenviar Wappa',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleResendWappa(params.row)}>
                    <DirectionsCarIcon color="action" />
                </IconButton>
            ),
        },
        {
            field: 'registerResend99',
            headerName: 'Cadastrar/Reenviar 99',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleRegisterResend99(params.row)}>
                    <AddCircleIcon color="success" />
                </IconButton>
            ),
        },
        {
            field: 'log',
            headerName: 'Log',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleLog(params.row)}>
                    <ListAltIcon />
                </IconButton>
            ),
        },
    ];

    const handleInactivate = (row) => {
        console.log('Inativar para:', row);
    };

    const handleReactivate = (row) => {
        console.log('Reativar para:', row);
    };

    const handleResend = (row) => {
        console.log('Reenviar para:', row);
    };

    const handleResendWappa = (row) => {
        console.log('Reenviar Wappa para:', row);
    };

    const handleRegisterResend99 = (row) => {
        console.log('Cadastrar/Reenviar 99 para:', row);
    };

    const handleLog = (row) => {
        console.log('Log para:', row);
    };

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={10} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{
                    wexpID: 'WExp ID',
                    registration: 'Matrícula',
                    name: 'Nome',
                    email: 'E-mail',
                    status: 'Situação',
                    phone: 'Telefone',
                    costCenter: 'Centro de Custo',
                    company: 'Empresa',
                    date: 'Data',
                }} />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
            <Footer />

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(prev => ({ ...prev, show: false }))}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(prev => ({ ...prev, show: false }))}>Ok</Button>
                </Box>
            </ModalWrapper>
        </Container>
    );
}

export default SupportGrid;
