import { useState } from 'react';
import { Container,Checkbox, Box, Button } from '@mui/material';

import { filterByValue } from "../../utils/filterGrid";

import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';
import CustomDataGrid from './components/CustomDataGrid';
import { ModalWrapper } from '../Modal';

import checked from '../../assets/images/checked.png';
import share from '../../assets/images/share.png';
import folder from '../../assets/images/folder-2.png';

const DriverDiagnosticGrid = ({ data, setSelectedCompany, setShowProductsModal, setShowAttachsModal  }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({title:'',show:false});
    const filteredData = filterByValue(data, search);
    const sizeCol = 160;

    const gridProps = {
        getRowId: (row) => row.diagnosticID,
        disableSelectionOnClick: true,
    }

    const showExportModalHandler = (title) => {
        setShowExportModal({title,show:true});
    }

    const columns = [
        {
            field: 'diagnosticID',
            headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field: 'company',
            headerName: 'Empresa',
            headerAlign: 'start',
            align: 'start',
            minWidth: 200,
            flex:1
        },
        {
            field: 'state',
            headerName: 'Estado',
            headerAlign: 'center',
            align: 'center',
            minWidth:100,
            flex:1
        },
        {
            field: 'created',
            headerName: 'Criado',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1,
            valueGetter: (params) => new Date(params.value).toLocaleDateString()
        },
        {
            field: 'updated',
            headerName: 'Modificado',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1,
            valueGetter: (params) => new Date(params.value).toLocaleDateString()
        },
        {
            field:'userID',
            headerName: 'ID do Usuário',
            headerAlign: 'start',
            align: 'start',
            minWidth: 200,
            flex:1
        },
        {
            field: 'user',
            headerName: 'Usuário',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field:'registration',
            headerName: 'Matrícula',
            headerAlign: 'start',
            align: 'start',
            minWidth:90,
            flex:1
        },
        {
            field:'isDefault',
            headerName: 'Default',
            headerAlign: 'center',
            align: 'center',
            minWidth: 80,
            flex:1,
            renderCell: (params) => <Checkbox checked={params.value === 1 ? true : false} />
        },
        {
            field:'active',
            headerName: 'Ativo',
            headerAlign: 'center',
            align: 'center',
            minWidth: 70,
            flex:1,
            renderCell: (params) => <Checkbox checked={params.value === 1 ? true : false} />
        },
        {
            field:'products',
            headerName: 'Produtos',
            headerAlign: 'center',
            align: 'center',
            minWidth: 90,
            flex:1,
            renderCell: (params) =>
                <img
                    src={share}
                    style={{width:25, cursor:'pointer'}}
                    onClick={() => {
                        setSelectedCompany(params.row)
                        setShowProductsModal(true)
                    }}
                />
        },
        {
            field:'attachs',
            headerName: 'Anexos',
            headerAlign: 'center',
            align: 'center',
            minWidth: 90,
            flex:1,
            renderCell: (params) =>
                <img
                    src={folder}
                    style={{width:25, cursor:'pointer'}}
                    onClick={() => {
                        setSelectedCompany(params.row)
                        setShowAttachsModal(true)
                    }}
                />
        },
    ]

    return(
        <Container sx={{width:'100%', height:'500px'}}>
            <Box mb={10} sx={{display:`flex`, justifyContent:'space-between'}}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler}
                headers={
                        {
                            diagnosticID:'ID',
                            company:'Empresa',
                            state:'Estado',
                            created:'Criado',
                            updated:'Modificado',
                            userID:'ID do Usuário',
                            user:'Usuário',
                            registration:'Matrícula',
                            isDefault:'Default',
                            active:'Ativo',
                        }
                    }  />
            </Box>
            <CustomDataGrid columns={columns} rows={filteredData} props={gridProps} />

            <ModalWrapper
                    showModal={showExportModal.show}
                    closeModalAction={() => setShowExportModal(prev => ({...prev,show:false}))}
                    title={showExportModal.title}
                    img={checked}
                    >
                    <Box>
                        <Button variant="contained" onClick={() => setShowExportModal(false)}>Ok</Button>
                    </Box>
                </ModalWrapper>

        </Container>
    )


}

export default DriverDiagnosticGrid;