import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    GetCompanyAdmins,
    DeleteCompanyAdmin
} from '../../../../store/_Entities/Company';

import { Button, Container } from '@mui/material';
import ResponsiveAppBar from '../../../../components/NavBar';
import Header from '../../../../components/Header';
import CompanyAdminGrid from '../../../../components/grids/CompanyAdminGrid';
import Footer from '../../../../components/Footer';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CompanyAdmin = () => {
    const params = useParams();
    const { company } = useSelector((state) => state.entities);
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const deleteHandler = (user) => {
        console.log('Deletar', user.companyAdministratorID);
        dispatch(DeleteCompanyAdmin(user.companyAdministratorID, user.userID));
        setRefresh(true);
    };

    useEffect(() => {
        dispatch(GetCompanyAdmins(params.id));
    }, []);

    useEffect(() => {
        if (!company.loading && refresh) {
            dispatch(GetCompanyAdmins(params.id));
            setRefresh(false);
        }
    }, [company, refresh]);

    useEffect(() => {
        if (showDeleteModal && company.success) {
            setShowDeleteModal(false);
        }
    }, [company]);

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Header
                    title="Admins Empresa"
                    addHref={'adm/company/admin/add'}
                />
                <CompanyAdminGrid
                    data={company.companyAdmins}
                    deleteHandler={deleteHandler}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    loading={company.loading}
                />
                <Footer />
            </Container>
        </>
    );
};

export default CompanyAdmin;
