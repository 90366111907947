import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import DriverSupportDetailsGrid from '../../../components/grids/DriverSupportDetailsGrid';

const mockData = [
  {
    rideID: 'R001',
    date: '2024-09-01',
    price: 35.5,
    currency: 'BRL',
    originAddress: 'Rua A, 123, São Paulo, SP',
    destinationAddress: 'Rua B, 456, São Paulo, SP',
    receipt: 'REC001',
    completed: 'Sim'
  },
  {
    rideID: 'R002',
    date: '2024-08-21',
    price: 50.0,
    currency: 'USD',
    originAddress: 'Avenida X, 789, Rio de Janeiro, RJ',
    destinationAddress: 'Avenida Y, 101, Rio de Janeiro, RJ',
    receipt: 'REC002',
    completed: 'Não'
  },
];

const convertData = (data) => {
  return {
    rideID: data.rideID,
    registration: data.registration,
    name: data.name,
    email: data.email,
    status: data.status,
    phone: data.phone,
    costCenter: data.costCenter,
    company: data.company,
    date: data.date
  };
};

const DriverSupportDetails = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    const convertedData = mockData.map(convertData);
    setData(convertedData);
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Suporte Driver</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
            </Box>
          </Grid>
        </Grid>
        <DriverSupportDetailsGrid data={data} />
      </Container>
    </>
  );
};

export default DriverSupportDetails;
