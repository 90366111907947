import { useState } from 'react';

import { Container, Typography, Box, Button } from '@mui/material';

import ResponsiveAppBar from '../../../components/NavBar';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import Footer from '../../../components/Footer';
import DriverGrid from '../../../components/grids/DriverGrid';
import { ModalWrapper } from '../../../components/Modal';

import cancel from '../../../assets/images/cancel.png';

const mockContent = [
    {
        id: 1,
        company: 'Empresa 1',
        user: 'Usuário 1',
        created: '01/01/2021',
        provider: 'uber'
    },
    {
        id: 2,
        company: 'Empresa 2',
        user: 'Usuário 2',
        created: '02/01/2021',
        provider: '99'
    },
    {
        id: 3,
        company: 'Empresa 3',
        user: 'Usuário 3',
        created: '03/01/2021',
        provider: 'uber'
    },
    {
        id: 4,
        company: 'Empresa 4',
        user: 'Usuário 4',
        created: '04/01/2021',
        provider: '99'
    },
    {
        id: 5,
        company: 'Empresa 5',
        user: 'Usuário 5',
        created: '05/01/2021',
        provider: 'uber'
    },
    {
        id: 6,
        company: 'Empresa 6',
        user: 'Usuário 6',
        created: '06/01/2021',
        provider: '99'
    },
    {
        id: 7,
        company: 'Empresa 7',
        user: 'Usuário 7',
        created: '07/01/2021',
        provider: 'uber'
    },
    {
        id: 8,
        company: 'Empresa 8',
        user: 'Usuário 8',
        created: '08/01/2021',
        provider: '99'
    }
];

const Driver = () => {
    const [showErrorsModal, setShowErrorsModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});

    const [filteredData, setFilteredData] = useState(mockContent);

    const filterData = (app) => {
        let newData = mockContent;

        if (app !== 'all') {
            newData = newData.filter((item) => item.provider === app);
        }

        setFilteredData(newData);
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Header title="Serviços de Corrida" addHref={'/adm/driver/add'}>
                    <HeaderSelect
                        label="Aplicativos"
                        defaultValue="all"
                        onChange={(event) => filterData(event.target.value)}
                        menuItems={[
                            { label: 'Todos', value: 'all' },
                            { label: 'Uber', value: 'uber' },
                            { label: '99', value: '99' }
                        ]}
                    />
                </Header>
                <DriverGrid
                    data={filteredData}
                    setSelectedCompany={setSelectedCompany}
                    setShowErrorsModal={setShowErrorsModal}
                />
                <Footer />
                <ModalWrapper
                    showModal={showErrorsModal}
                    closeModalAction={() => setShowErrorsModal(false)}
                    title={'Log de Erros'}
                    img={cancel}
                >
                    <Box>
                        <Typography variant="body1">
                            Erro: {selectedCompany.company}
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => setShowErrorsModal(false)}
                        >
                            Fechar
                        </Button>
                    </Box>
                </ModalWrapper>
            </Container>
        </>
    );
};

export default Driver;
