import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

// Componente InfoCard atualizado para tornar o campo `values` opcional
const InfoCard = ({ title, values = [] }) => (
  <Card sx={{ textAlign: 'center', m: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <CardContent>
      {/* Título opcional */}
      {title && (
        <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
      )}
      {/* Verifica se há valores; se não, exibe uma mensagem padrão */}
      {values.length > 0 ? (
        values.map((value, index) => (
          <Typography key={index} variant="h6" component="div" sx={{ fontWeight: 500 }}>
            {value}
          </Typography>
        ))
      ) : (
        <Typography variant="body2" component="div" sx={{ fontStyle: 'italic', color: 'gray' }}>
          Nenhum valor disponível
        </Typography>
      )}
    </CardContent>
  </Card>
);

export default InfoCard;
