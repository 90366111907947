import { Box,Typography } from "@mui/material"

import CustomDataGrid from "./components/CustomDataGrid"

const DiagnosticAttachmentsGrid = ({ attachments }) => {

    const columns = [
        {
            field:'name',
            headerName: 'Arquivo',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field:'user',
            headerName: 'Usuário',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field:'created',
            headerName: 'Data',
            headerAlign: 'center',
            align: 'center',
            flex:1
        }
    ]

    const gridProps ={
        getRowId: (row) => row.diagnosticID,
        density:'compact',
        disableSelectionOnClick: true,
        hideFooterPagination:true,
        rowsPerPageOptions:[]
    }

    return(
        <>

            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant="h5" sx={{fontWeight:'bold'}} >
                    Anexos
                </Typography>
            </Box>
            <Box>
                <CustomDataGrid rows={attachments} columns={columns} width={300} props={gridProps} />
            </Box>
        </>
    )

}

export default DiagnosticAttachmentsGrid;