import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Header = ({ title, addHref, children }) => {
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    py={1}
                    sx={{
                        marginBottom: '10px',
                        borderBottom: '1px solid black'
                    }}
                >
                    <h2>{title}</h2>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'end'
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 5 }}>{children}</Box>
                        <Button variant="contained" href={addHref}>
                            <AddIcon />
                            Adicionar
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Header;
