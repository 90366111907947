import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IState} from './interfaces/Company'
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    companies: [],
    selectedCompany: {
        CompanyID: 0,
        Name: '',
        GiveName: '',
        LogoImageFile: '',
        Document: '',
        Street: '',
        Number: '',
        Neighborhood: '',
        City: '',
        State: '',
        Active: 0,
        CompanyConfigurations: []
    },
    companyAdmins: [],
};

export const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        COMPANY_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        COMPANY_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        COMPANY_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.companies = action.payload.result;
        },
        COMPANY_CONFIGURATION_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.selectedCompany = action.payload.result;
        },
        COMPANY_ADMIN_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.companyAdmins = action.payload.result
        },
        COMPANY_ADMIN_DELETE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        }
    }
})

export const { COMPANY_REQUESTED, COMPANY_FAILED, COMPANY_SUCCESS,COMPANY_CONFIGURATION_SUCCESS, COMPANY_ADMIN_SUCCESS,COMPANY_ADMIN_DELETE_SUCCESS } = slice.actions;

export default slice.reducer;

export const GetCompanies = () => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Company.Company
        },
        onAction: {
            onStart: COMPANY_REQUESTED.type,
            onError: COMPANY_FAILED.type,
            onSuccess: COMPANY_SUCCESS.type,
        }
    })
}

export const GetCompanyWithConfiguration = (companyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Company.GetCompanyWithConfiguration(companyID)
        },
        onAction: {
            onStart: COMPANY_REQUESTED.type,
            onError: COMPANY_FAILED.type,
            onSuccess: COMPANY_CONFIGURATION_SUCCESS.type,
        }
    })
}

export const GetCompanyAdmins = (companyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Company.GetCompanyAdmin(companyID)
        },
        onAction: {
            onStart: COMPANY_REQUESTED.type,
            onError: COMPANY_FAILED.type,
            onSuccess: COMPANY_ADMIN_SUCCESS.type,
        }
    })
}

export const DeleteCompanyAdmin = (companyID: number, userID: string) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'DELETE',
            url: Endpoints.Company.DeleteCompanyAdmin(companyID, userID)
        },
        onAction: {
            onStart: COMPANY_REQUESTED.type,
            onError: COMPANY_FAILED.type,
            onSuccess: COMPANY_ADMIN_DELETE_SUCCESS.type,
            showToast: true
        }
    })
}

