import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import DriverSupportDetailsGrid from '../../../components/grids/DriverSupportDetailsGrid';
import ExpenReportDetailsGrid from '../../../components/grids/ExpenReportDetailsGrid';

const mockData = [
  {
    wexpID: 'W001',
    creationDate: '2024-09-01',
    description: 'Reembolso de Viagem',
    status: 'Aprovado',
    reportValue: 1500.00,
    advanceValue: 500.00,
  },
  {
    wexpID: 'W002',
    creationDate: '2024-08-21',
    description: 'Compra de Materiais',
    status: 'Pendente',
    reportValue: 300.00,
    advanceValue: 0.00,
  },
];

const convertData = (data) => {
  return {
    wexpID: data.wexpID,
    creationDate: data.creationDate,
    description: data.description,
    status: data.status,
    reportValue: data.reportValue,
    advanceValue: data.advanceValue,
  };
};

const ExpenReportDetails = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    const convertedData = mockData.map(convertData);
    setData(convertedData);
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Relatórios do Usuário</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
            </Box>
          </Grid>
        </Grid>
        <ExpenReportDetailsGrid data={data} />
      </Container>
    </>
  );
};

export default ExpenReportDetails;
