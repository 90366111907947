import { useState } from "react";

import { Box, Button, Container, FormControl, Grid, TextField } from "@mui/material";

import ResponsiveAppBar from "../../../components/NavBar";
import Header from "../../../components/Header";
import SftpSettingsGrid from "../../../components/grids/SftpSettingsGrid";
import { ModalWrapper } from "../../../components/Modal";
import Footer from "../../../components/Footer";


const mockContent = [
    {
        id:1,
        user:'u39ru2fj201',
        sshKey:'wexp-privatekey',
        secretPhrase:'99',
        publicKey:'213as34241fasdadas1'
    },
    {
        id:2,
        user:'u39ru2fj202',
        sshKey:'wexp-privatekey',
        secretPhrase:'mb',
        publicKey:'213as34241fasdadas2'
    },
    {
        id:3,
        user:'u39ru2fj203',
        sshKey:'wexp-privatekey',
        secretPhrase:'uber',
        publicKey:'213as34241fasdadas3'
    },
    {
        id:4,
        user:'u39ru2fj204',
        sshKey:'wexp-privatekey',
        secretPhrase:'99',
        publicKey:'213as34241fasdadas4'
    },
    {
        id:5,
        user:'u39ru2fj205',
        sshKey:'wexp-privatekey',
        secretPhrase:'uber',
        publicKey:'213as34241fasdadas5'
    },

]

const SftpSettings = () => {

    const data = mockContent;

    const [selectedItem,setSelectedItem] = useState({})
    const [showEditModal,setShowEditModal] = useState(false)

    return(
        <>
            <ResponsiveAppBar />
            <Container>
                <Header title="Configurações SFTP" addHref={'/adm/sftp-settings/add'}/>
                <SftpSettingsGrid data={data} setSelectedItem={setSelectedItem} setShowEditModal={setShowEditModal}/>
                <Footer/>
                <ModalWrapper showModal={showEditModal} closeModalAction={() => setShowEditModal(false)} title="Classificar Produto">
                    <FormControl sx={{display:'flex',flexDirection:'column',gap:2}}>
                        <Box>
                            <label htmlFor="legalName">Usuário</label>
                            <TextField id='legalName' variant='outlined' fullWidth defaultValue={selectedItem.user}/>
                        </Box>
                        <Box>
                            <label htmlFor="secretPhrase">Frase secreta</label>
                            <TextField id='secretPhrase' variant='outlined' fullWidth placeholder='Digite...'/>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-around'}>
                            <Button variant='contained' onClick={() => console.log('Salvar')}>Salvar</Button>
                            <Button variant='outlined' onClick={() => setShowEditModal(false)}>Cancelar</Button>
                        </Box>
                    </FormControl>
                </ModalWrapper>
            </Container>
        </>
    )
}

export default SftpSettings;