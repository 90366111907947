import { useState } from 'react';

import { Container, Box, Button } from '@mui/material';

import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';

import cancel from '../../assets/images/cancel.png';
import alerta from '../../assets/images/alerta.png';
import checked from '../../assets/images/checked.png';

import { filterByValue } from '../../utils/filterGrid';

import { ModalWrapper } from '../Modal';
import CustomDataGrid from './components/CustomDataGrid';

const AdminGrid = ({
    data,
    loading,
    deleteHandler,
    showDeleteModal,
    setShowDeleteModal
}) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({
        title: '',
        show: false
    });
    const [selectedUser, setSelectedUser] = useState({});
    const sizeCol = 160;

    const filteredData = filterByValue(data, search);

    const gridProps = {
        disableSelectionOnClick: true,
        getRowId: (row) => row.userID,
        loading: loading
    };

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    };

    const columns = [
        {
            field: 'userID',
            headerName: 'Código',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nome',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Deletar',
            renderCell: (params) => (
                <Box
                    alignItems={'center'}
                    onClick={() => {
                        setSelectedUser(params.row);
                        setShowDeleteModal(true);
                    }}
                >
                    <img
                        src={cancel}
                        alt="Deletar"
                        style={{ width: '20px', cursor: 'pointer' }}
                    />
                </Box>
            ),
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box
                mb={10}
                sx={{ display: `flex`, justifyContent: 'space-between' }}
            >
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu
                    data={filteredData}
                    showExportModalHandler={showExportModalHandler}
                    headers={{
                        userID: 'Código',
                        name: 'Nome',
                        email: 'E-mail'
                    }}
                />
            </Box>
            <CustomDataGrid
                rows={filteredData}
                columns={columns}
                props={gridProps}
            />
            <ModalWrapper
                showModal={showDeleteModal}
                closeModalAction={() => setShowDeleteModal(false)}
                title="Deseja Deletar o usuário?"
                img={alerta}
            >
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box>{selectedUser.name}</Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => deleteHandler(selectedUser)}
                    >
                        Confirmar
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        Cancelar
                    </Button>
                </Box>
            </ModalWrapper>

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(false)}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => setShowExportModal(false)}
                    >
                        Ok
                    </Button>
                </Box>
            </ModalWrapper>
        </Container>
    );
};

export default AdminGrid;
