import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Container, Box, Stepper, Step, StepLabel, Button } from "@mui/material";

import ResponsiveAppBar from "../../../components/NavBar";
import SftpStep1 from "../../../components/forms/sftpSettings/SftpStep1";
import SftpStep2 from "../../../components/forms/sftpSettings/SftpStep2";
import SftpStep3 from "../../../components/forms/sftpSettings/sftpStep3";
import Footer from "../../../components/Footer";
import { ModalWrapper } from "../../../components/Modal";

import checked from '../../../assets/images/checked.png';

const SftpSettingsCreate = () => {
    const navigate = useNavigate();
    const [formStep, setFormStep] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const onSubmit = (data) => {
        setShowModal(true)
        console.log(data)
    }

    const form = useForm({
        defaultValues: {
            company: '',
            voucherName: '',
            sshKey: '',
            userID:'',
        },
        mode: 'onBlur',
    })

    const {
        handleSubmit,
    } = form;

    return(
        <>
            <ResponsiveAppBar />
            <Container>
                    <Box xs={12} py={1} mb={'10px'}>
                        <h2>Configurações SFTP</h2>
                    </Box>

                    <Box>
                        <Stepper activeStep={formStep} alternativeLabel
                            sx={{
                                    '& .MuiStepIcon-root': {
                                        fontSize: '2.5rem',
                                        zIndex: 1

                                    },
                                    '& .MuiStepConnector-root': {
                                        top: '1.25rem',
                                        width: 'calc(100% - 100px)',
                                        marginLeft: 'calc(50% - 160px)',
                                    },
                                }}
                        >
                            <Step>
                                <StepLabel>Adicionar novo voucher</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Gerar Chave SSH</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Adicionar novas configurações</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>

                    <Box>
                        <Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormProvider {...form}>
                                    {formStep === 0 && <SftpStep1 nextStep={() => setFormStep( prev => prev + 1)} />}
                                    {formStep === 1 && <SftpStep2  nextStep={() => setFormStep( prev => prev + 1)} />}
                                    {formStep === 2 && <SftpStep3 />}
                                </FormProvider>
                            </form>
                        </Box>
                    </Box>
                    <Footer />
                    <ModalWrapper
                        showModal={showModal}
                        closeModalAction={() => navigate('/adm/sftp-settings')}
                        title={'Configuração Salva com Sucesso'}
                        img={checked}
                    >
                    <Box>
                        <Button variant="contained" onClick={() => navigate('/adm/sftp-settings')}>Ok</Button>
                    </Box>
                </ModalWrapper>
            </Container>
        </>
    )
}

export default SftpSettingsCreate;