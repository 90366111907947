import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import ExpenReportDetailsGrid from '../../../components/grids/ExpenReportDetailsGrid';
import ExpenUserExpensesDetailsGrid from '../../../components/grids/ExpenUserExpensesDetailsGrid';
import DataDisplay from '../../../components/DataDisplay';

const mockData = [
  {
    expenseID: 'E001',
    alert: 'Urgente',
    file: 'receipt.pdf',
    expenseType: 'Viagem',
    value: 1500.00,
    currency: 'USD',
    date: '2024-09-01',
    description: 'Reembolso de Viagem',
    costCenter: 'Marketing',
    paymentMethod: 'Cartão Corporativo',
    project: 'Projeto X',
    reason: 'Viagem a negócios',
    justification: 'Reunião com clientes',
  },
  {
    expenseID: 'E002',
    alert: 'Normal',
    file: 'invoice.pdf',
    expenseType: 'Material',
    value: 300.00,
    currency: 'USD',
    date: '2024-08-21',
    description: 'Compra de Materiais',
    costCenter: 'Operações',
    paymentMethod: 'Transferência Bancária',
    project: 'Projeto Y',
    reason: 'Compras de rotina',
    justification: 'Estoque de materiais de escritório',
  },
];

const dataDisplayData = {
  despesas: [
    { label: 'Valor Despesas Relatório', valor: 'BRL 2.760,00' },
    { label: 'Valor Despesas Relatório: USD', valor: 'USD 2.024,14' },
    { label: 'Valor Despesas Totais', valor: 'BRL 24,08' },
    { label: 'Valor Despesas Totais', valor: 'USD 224,14' },
    { label: 'Valor Despesas Cadastradas', valor: 'BRL 2.760,00' },
    { label: 'Valor Despesas Cadastradas', valor: 'USD 224,14' }
  ]
};

const convertData = (data) => {
  return {
    expenseID: data.expenseID,
    alert: data.alert,
    file: data.file,
    expenseType: data.expenseType,
    value: data.value,
    currency: data.currency,
    date: data.date,
    description: data.description,
    costCenter: data.costCenter,
    paymentMethod: data.paymentMethod,
    project: data.project,
    reason: data.reason,
    justification: data.justification,
  };
};

const ExpenUserExpensesDetails = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    const convertedData = mockData.map(convertData);
    setData(convertedData);
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Despesas do Usuário</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
            </Box>
          </Grid>
        </Grid>
        <DataDisplay data={dataDisplayData} />
        <ExpenUserExpensesDetailsGrid data={data} />
      </Container>
    </>
  );
};

export default ExpenUserExpensesDetails;
