import React from 'react';
import { Box, Button, Checkbox, TextField, Typography, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const CreateInvoice = ({ onSubmit }) => {
  const { control, formState: { errors }, trigger } = useFormContext();

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <>
      <Box mt={5} minHeight={300} boxShadow="0px 0px 4px 0px #00000040" p={5} borderRadius={2} display="flex" flexDirection="column" gap={4}>
        <Typography variant="h5">Adicionar Nova Fatura</Typography>

        <Controller
          name="invoiceClosingDay"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Dia do Mês de Fechamento"
              variant="outlined"
              fullWidth
              error={Boolean(errors.invoiceClosingDay)}
              helperText={errors.invoiceClosingDay?.message}
            />
          )}
        />

        <Controller
          name="invoiceDueDays"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Dias Úteis para Vencimento"
              variant="outlined"
              fullWidth
              error={Boolean(errors.invoiceDueDays)}
              helperText={errors.invoiceDueDays?.message}
            />
          )}
        />

        <Controller
          name="invoiceSuffix"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Sufixo do Identificador"
              variant="outlined"
              fullWidth
              error={Boolean(errors.invoiceSuffix)}
              helperText={errors.invoiceSuffix?.message}
            />
          )}
        />

        <Controller
          name="purchaseOrder"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Número do Pedido de Compra"
              variant="outlined"
              fullWidth
              error={Boolean(errors.purchaseOrder)}
              helperText={errors.purchaseOrder?.message}
            />
          )}
        />

        {['Nota de Débito', 'Csv de Corridas', 'Csv de Usuários KM', 'Csv de Usuários Expen'].map((name) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={name.replace(/([A-Z])/g, ' $1').trim()}
              />
            )}
          />
        ))}

        <Box display="flex" gap={2} justifyContent="end" mt={2}>
          <Button variant="outlined" onClick={() => window.location.href = '/invoicing/config'}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSubmit}>Salvar</Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateInvoice;
