export function filterByValue(array, string) {
    return array.filter((o) =>
        Object.keys(o).some((k) => {
            if (typeof o[k] === 'string') {
                return o[k].toLowerCase().includes(string.toLowerCase());
            } else {
                return false;
            }
        })
    );
}
