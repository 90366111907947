import React, { useState } from "react";
import { 
  Container, 
  Grid, 
  Button, 
  Typography, 
  Box, 
  MenuItem, 
  Select, 
  TextField 
} from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import ResponsiveAppBar from "../../components/NavBar";
import AuditGrid from "../../components/grids/AuditGrid";
// import AuditGrid from "./AuditGrid ;

const mockProviders = ["99", "Uber", "Wappa", "Original", "Táxi Digital"];
const mockData = [
  {
    id: 1,
    receiptID: "R001",
    transactionID: "T001",
    price: 50.0,
    tip: 5.0,
    adjust: 0.0,
    startAddress: "Rua A",
    endAddress: "Rua B",
    user: "João",
    billingCode: "BC001",
    billingID: "B001",
  },
];

const Audit = () => {
  const [provider, setProvider] = useState("");
  const [showForm, setShowForm] = useState(false);
  const methods = useForm();

  const handleProcessRides = (data) => {
    console.log("Processando Corridas:", data);
    setShowForm(false);
  };

  return (
    <>
      <ResponsiveAppBar />
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={3}>
          <Grid 
            item 
            xs={12} 
            sx={{ 
              marginBottom: "20px", 
              borderBottom: "1px solid black", 
              paddingBottom: "10px" 
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">Auditoria de Corridas</Typography>
              <Button variant="contained" onClick={() => setShowForm(true)}>
                Processar Corridas
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormProvider {...methods}>
              <Grid container spacing={2} mb={3}>
                <Grid item xs={4}>
                  <Controller
                    name="startDate"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Data Inicial"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="endDate"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Data Final"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                    displayEmpty
                    fullWidth
                    sx={{ minWidth: 200 }}
                  >
                    <MenuItem value="">Selecione o Fornecedor</MenuItem>
                    {mockProviders.map((provider) => (
                      <MenuItem key={provider} value={provider}>
                        {provider}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              {!showForm && (
                <>
                  <AuditGrid data={mockData} loading={false} />
                  <Box mt={4} display="flex" justifyContent="space-between">
                    <Typography>Total de Corridas: {mockData.length}</Typography>
                    <Typography>
                      Total Valor: R$ {mockData.reduce((acc, row) => acc + row.price, 0).toFixed(2)}
                    </Typography>
                  </Box>
                </>
              )}

              {showForm && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Processar Corridas
                  </Typography>

                  <TextField
                    type="file"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    label="ID da Transação"
                    fullWidth
                    margin="normal"
                    placeholder="Insira IDs separados por vírgula"
                  />

                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={() => setShowForm(false)}>
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={methods.handleSubmit(handleProcessRides)}
                    >
                      Processar
                    </Button>
                  </Box>
                </Grid>
              )}
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Audit;
