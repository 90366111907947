import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";

import { Container, Box, Button, IconButton } from "@mui/material";
import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import { ModalWrapper } from "../Modal";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import CancelIcon from '@mui/icons-material/Cancel';

import checked from '../../assets/images/checked.png';
import Footer from "../Footer";

const ExpenReportDetailsGrid = ({ data }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({ title: '', show: false });
    const filteredData = filterByValue(data, search);
    const sizeCol = 200;

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    }

    const gridProps = {
        getRowId: (row) => row.wexpID,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'wexpID',
            headerName: 'WExp',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'creationDate',
            headerName: 'Data de Criação',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'description',
            headerName: 'Descrição',
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'reportValue',
            headerName: 'Valor Relatório',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'advanceValue',
            headerName: 'Valor Adiantamento',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'approvalFollow',
            headerName: 'Acompanhar Aprovação',
            headerAlign: 'center',
            align: 'center',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleApprovalFollow(params.row)}>
                    <CheckCircleOutlineIcon />
                </IconButton>
            ),
        },
        {
            field: 'approvalHistory',
            headerName: 'Histórico de Aprovação',
            headerAlign: 'center',
            align: 'center',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleApprovalHistory(params.row)}>
                    <HistoryIcon />
                </IconButton>
            ),
        },
        {
            field: 'reject',
            headerName: 'Reprovar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleRejection(params.row)}>
                    <CancelIcon />
                </IconButton>
            ),
        },
    ];

    const handleApprovalFollow = (row) => {
        console.log('Acompanhar Aprovação para:', row);
    };

    const handleApprovalHistory = (row) => {
        console.log('Histórico de Aprovação para:', row);
    };

    const handleRejection = (row) => {
        console.log('Reprovar para:', row);
    };

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={10} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{
                    wexpID: 'WExp',
                    creationDate: 'Data de Criação',
                    description: 'Descrição',
                    status: 'Status',
                    reportValue: 'Valor Relatório',
                    advanceValue: 'Valor Adiantamento',
                    approvalFollow: 'Acompanhar Aprovação',
                    approvalHistory: 'Histórico de Aprovação',
                    reject: 'Reprovar'
                }} />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
            <Footer />

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(prev => ({ ...prev, show: false }))}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(prev => ({ ...prev, show: false }))}>Ok</Button>
                </Box>
            </ModalWrapper>
        </Container>
    )
}

export default ExpenReportDetailsGrid;
