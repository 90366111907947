import React, { useEffect, useState } from 'react';

import './assets/styles/main.css';

import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Login from './containers/Login';
import Home from './containers/Home';
import Admins from './containers/Adm/Admin/Admins/Admins';

import CompanySettings from './containers/Adm/Admin/Company/CompanySettings';
import Companies from './containers/Adm/Admin/Company/Companies';
import CompanyAdmin from './containers/Adm/Admin/Company/CompanyAdmin';
import SftpSettings from './containers/Adm/SftpSettings/SftpSettings';
import SftpSettingsCreate from './containers/Adm/SftpSettings/SftpSettingsCreate';
import DriverSupport from './containers/Adm/DriverSupport/DriverSupport';
import DriverDiagnostic from './containers/Adm/Driver/DriverDiagnostic';
import Driver from './containers/Adm/Driver/Driver';
import DriverProducts from './containers/Adm/Driver/DriverProducts';

// Wrapper
import { PageContainer } from './components/wrappers/PageContainer';
// Store
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, USER_LOGOUT } from './store/_Entities/User';

// Hooks
import useAutoLogout from './hooks/useAutoLogout';

// MUI
import { Alert, Snackbar } from '@mui/material';
import DriverSupportDetails from './containers/Adm/DriverSupport/DriverSupportDetails';
import ExpenSupport from './containers/Adm/ExpenSupport/ExpenSupport';
import ExpenReportDetails from './containers/Adm/ExpenSupport/ExpenReportDetails';
import ExpenUserExpensesDetails from './containers/Adm/ExpenSupport/ExpenUserExpensesDetails';
import Support from './containers/Adm/BackofficeSupport/Support';
import InvoicingConfig from './containers/Invoicing/invoicingConfig';
import InvoiceFiles from './containers/Invoicing/InvoiceFiles';
import Invoicing from './containers/Invoicing/invoicing';
import Audit from './containers/Audit/Audit';

const MAX_IDLE_TIME_MS = 2.4e6; // 40 minutes
const IDLE_WARNING_TIME_MS = 60000; // 1 minute

function App() {
    const { user } = useSelector((state) => state.entities);

    const idleTimer = useAutoLogout(MAX_IDLE_TIME_MS);

    const [openIdleTimeWarningSnackbar, setOpenIdleTimeWarningSnackbar] =
        useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const autoLogoutUser = () => {
        setOpenIdleTimeWarningSnackbar(false);
        dispatch(USER_LOGOUT());
        navigate('/login');
    };

    useEffect(() => {
        const date1 = new Date(user.loginDate);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        if (diffTime > MAX_IDLE_TIME_MS) autoLogoutUser();
        if (idleTimer <= IDLE_WARNING_TIME_MS)
            setOpenIdleTimeWarningSnackbar(true);
        if (idleTimer === MAX_IDLE_TIME_MS)
            setOpenIdleTimeWarningSnackbar(false);
        if (idleTimer === 0) autoLogoutUser();
    }, [idleTimer]);

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        user.isLoggedIn === false ? (
                            <Navigate to="/login" />
                        ) : (
                            <Navigate to="/home" />
                        )
                    }
                />

                <Route path="/login" element={<Login />} />

                <Route
                    path="/home"
                    element={
                        <PageContainer protected>
                            <Home />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/admins"
                    element={
                        <PageContainer protected>
                            <Admins />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/company/add"
                    element={
                        <PageContainer protected>
                            <CompanySettings />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/companies"
                    element={
                        <PageContainer protected>
                            <Companies />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/company/edit/:id"
                    element={
                        <PageContainer protected>
                            <CompanySettings />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/company/admins/:id"
                    element={
                        <PageContainer protected>
                            <CompanyAdmin />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/sftp-settings"
                    element={
                        <PageContainer protected>
                            <SftpSettings />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/sftp-settings/add"
                    element={
                        <PageContainer protected>
                            <SftpSettingsCreate />
                        </PageContainer>
                    }
                />

                <Route
                    path="adm/driver/support"
                    element={
                        <PageContainer protected>
                            <DriverSupport />
                        </PageContainer>
                    }
                />
                <Route
                    path="adm/driver/support/details"
                    element={
                        <PageContainer protected>
                            <DriverSupportDetails />
                        </PageContainer>
                    }
                />
                <Route
                    path="/adm/expen/support"
                    element={
                        <PageContainer protected>
                            <ExpenSupport />
                        </PageContainer>
                    }
                />
                <Route
                    path="/adm/expen/report/details"
                    element={
                        <PageContainer protected>
                            <ExpenReportDetails />
                        </PageContainer>
                    }
                />
                <Route
                    path="/adm/expen/user-expenses/details"
                    element={
                        <PageContainer protected>
                            <ExpenUserExpensesDetails />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/driver/products"
                    element={
                        <PageContainer protected>
                            <DriverProducts />
                        </PageContainer>
                    }
                />
                <Route
                    path="/adm/support"
                    element={
                        <PageContainer protected>
                            <Support />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/driver/diagnostic"
                    element={
                        <PageContainer protected>
                            <DriverDiagnostic />
                        </PageContainer>
                    }
                />

                <Route
                    path="/adm/driver"
                    element={
                        <PageContainer protected>
                            <Driver />
                        </PageContainer>
                    }
                />
                <Route
                    path="/invoicing/config"
                    element={
                        <PageContainer protected>
                            <InvoicingConfig />
                        </PageContainer>
                    }
                />
                <Route
                    path="/invoicing/invoice-files"
                    element={
                        <PageContainer protected>
                            <InvoiceFiles />
                        </PageContainer>
                    }
                />
                <Route
                    path="/invoicing"
                    element={
                        <PageContainer protected>
                            <Invoicing />
                        </PageContainer>
                    }
                />
                <Route
                    path="/audit"
                    element={
                        <PageContainer protected>
                            <Audit />
                        </PageContainer>
                    }
                />
            </Routes>

            {openIdleTimeWarningSnackbar && (
                <Snackbar open={true}>
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        Alerta de inatividade ! Você sera redirecionado para a
                        pagina de login em {idleTimer} segundos.
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

export default App;
