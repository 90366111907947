import { useState } from "react";

import { useFormContext } from "react-hook-form";

import { Box, Button, Typography } from "@mui/material";

const SftpStep2 = ({nextStep}) => {

    const {setValue} = useFormContext()


    const [sshKey,setSshKey] = useState('')
    const [sshKeyError,setSshKeyError] = useState(false)

    const handleGenerateKey = () => {

        setSshKey('ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABgQDZ');
        setSshKeyError(false)
        setValue('sshKey','ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABgQDZ')

    }

    const handleNextStep = () => {
        if(sshKey){
            nextStep()
        }else{
            setSshKeyError(true)
        }
    }

    return(
        <>
            <Box mt={5} minHeight={200} boxShadow={'0px 0px 4px 0px #00000040'} p={5} borderRadius={2} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                <Box>
                    <h3>Gerar Chave</h3>
                </Box>


            <Box>
                <Box flex={1} maxWidth={400}>
                    <Button variant='contained' size="large" onClick={handleGenerateKey}>Gerar</Button>
                </Box>
                <Box flex={1} maxWidth={400}>
                    {sshKey && (
                        <>
                            <h4>Chave gerada</h4>
                            <h4 style={{color:'#888888'}}>{sshKey}</h4>
                        </>
                    )}
                    {sshKeyError && (
                        <Typography variant="caption" color="error">
                            É necessário a geração de uma chave
                        </Typography>
                    )}
                </Box>
            </Box>

            </Box>
            <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
                <Button variant='outlined'>Cancelar</Button>
                <Button variant='contained' onClick={handleNextStep}>Próximo</Button>
            </Box>
        </>
    )

}

export default SftpStep2;