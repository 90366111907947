import { useState } from 'react';
import { Container, Box, Button } from '@mui/material';

import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';

import cancel from '../../assets/images/cancel.png';
import alerta from '../../assets/images/alerta.png';
import checked from '../../assets/images/checked.png';

import { filterByValue } from '../../utils/filterGrid';
import { ModalWrapper } from '../Modal';
import CustomDataGrid from './components/CustomDataGrid';

const AuditGrid = ({
    data,
    loading,
    deleteHandler,
    showDeleteModal,
    setShowDeleteModal
}) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({
        title: '',
        show: false
    });
    const [selectedRow, setSelectedRow] = useState({});
    const sizeCol = 160;

    const filteredData = filterByValue(data, search);

    const gridProps = {
        disableSelectionOnClick: true,
        getRowId: (row) => row.id,
        loading: loading,
    };

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    };

    const columns = [
        { field: 'receiptID', headerName: 'ID do Recibo', minWidth: sizeCol, flex: 1 },
        { field: 'transactionID', headerName: 'ID da Transação', minWidth: sizeCol, flex: 1 },
        { field: 'price', headerName: 'Valor da Corrida', minWidth: sizeCol, flex: 1 },
        { field: 'tip', headerName: 'Gorjeta', minWidth: sizeCol, flex: 1 },
        { field: 'adjust', headerName: 'Ajuste', minWidth: sizeCol, flex: 1 },
        { field: 'startAddress', headerName: 'Endereço Inicial', minWidth: 200, flex: 1 },
        { field: 'endAddress', headerName: 'Endereço Final', minWidth: 200, flex: 1 },
        { field: 'user', headerName: 'Usuário', minWidth: sizeCol, flex: 1 },
        { field: 'billingCode', headerName: 'Código de Faturamento', minWidth: sizeCol, flex: 1 },
        { field: 'billingID', headerName: 'ID de Faturamento', minWidth: sizeCol, flex: 1 },
        {
            field: 'actions',
            headerName: 'Deletar',
            renderCell: (params) => (
                <Box
                    alignItems={'center'}
                    onClick={() => {
                        setSelectedRow(params.row);
                        setShowDeleteModal(true);
                    }}
                >
                    <img
                        src={cancel}
                        alt="Deletar"
                        style={{ width: '20px', cursor: 'pointer' }}
                    />
                </Box>
            ),
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={3} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu
                    data={filteredData}
                    showExportModalHandler={showExportModalHandler}
                    headers={{
                        receiptID: 'ID do Recibo',
                        transactionID: 'ID da Transação',
                        price: 'Valor',
                        tip: 'Gorjeta',
                        adjust: 'Ajuste'
                    }}
                />
            </Box>

            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />

            <ModalWrapper
                showModal={showDeleteModal}
                closeModalAction={() => setShowDeleteModal(false)}
                title="Deseja Deletar este registro?"
                img={alerta}
            >
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box>{selectedRow.receiptID}</Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button variant="contained" onClick={() => deleteHandler(selectedRow)}>
                        Confirmar
                    </Button>
                    <Button variant="outlined" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                </Box>
            </ModalWrapper>

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(false)}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(false)}>
                        Ok
                    </Button>
                </Box>
            </ModalWrapper>
        </Container>
    );
};

export default AuditGrid;
