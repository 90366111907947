import { Button, Container, FormControl, Grid, InputLabel, Select, Box, MenuItem, Typography } from "@mui/material";
import ResponsiveAppBar from "../../../components/NavBar";
import { useEffect, useState } from "react";
import DriverProductsGrid from "../../../components/grids/DriverProductsGrid";
import { ModalWrapper } from "../../../components/Modal";
import Footer from "../../../components/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { GetProductAdmins } from "../../../store/_Entities/ProductAdmin";


  const mockCategories = [
    {
        categoryID: 1,
        description: "Nao classificado"
    },
    {
        categoryID: 2,
        description: "Basic"
    },
    {
        categoryID: 3,
        description: "Select"
    },
    {
        categoryID: 4,
        description: "Premium"
    },
      {
          categoryID: 5,
          description: "Taxi Basic"
      },
      {
          categoryID: 6,
          description: "Taxi Premium"
      },
      {
          categoryID: 7,
          description: "Bag Basic"
      },
      {
          categoryID: 8,
          description: "Bag Premium"
      },
      {
        categoryID: 9,
        description: "Comfort"
    },
    {
        categoryID: 10,
        description: "Entrega"
    },
    {
        categoryID: 11,
        description: "Nao Aplica"
    },
  ]

const DriverProducts = () => {
    const [data,setData] = useState([]);
    const [category,setCategory] = useState([]);
    const [selectedProduct,setSelectedProduct] = useState({});
    const [showModal, setShowModal] = useState(false);
    const { ProductAdmin } = useSelector((state) => state.entities);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(GetProductAdmins());
    }, []);
    useEffect(() => {
      setData(() => ProductAdmin.productAdmins.map((product) => {
        const categoryDescription = mockCategories.find(cat => cat.categoryID === product.categoryID)?.description || "Unknown";
        return {
          ...product,
          category: categoryDescription
        };
      }));
      setCategory(mockCategories);
    }, [ProductAdmin.productAdmins]);

    return(
        <>
            <ResponsiveAppBar />
            <Container>
                <Grid container>
                    <Grid item xs={12} py={1} sx={{ marginBottom: "10px", borderBottom: '1px solid black' }}>
                        <h2>Produtos</h2>
                    </Grid>
                </Grid>
                <DriverProductsGrid data={data} setSelectedProduct={setSelectedProduct} setShowModal={setShowModal} />
                <Footer/>

                <ModalWrapper showModal={showModal} closeModalAction={() => setShowModal(false)} title={'Classificar Produto'}>
                    <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <Box>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="company-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="company-select-label"
                                id="company-simple-select"
                                defaultValue=""
                                displayEmpty
                                label="Categoria"
                            >
                                {mockCategories.map((category, index) => <MenuItem key={index} value={category.categoryID}>{category.description}</MenuItem>)}
                            </Select>
                        </FormControl>
                        </Box>
                        <Box display={'flex'} gap={2}>
                            <Button variant="contained" color="primary" onClick={() => setShowModal(false)}>Salvar</Button>
                            <Button variant="outlined" onClick={() => setShowModal(false)}>Cancelar</Button>
                        </Box>
                    </Box>
                </ModalWrapper>
            </Container>
        </>
    )
}

export default DriverProducts;