import { Box, Typography} from "@mui/material";
import CustomDataGrid from "./components/CustomDataGrid";

const DiagnosticProductsGrid = ({ products }) => {

    const columns = [
        {
            field:'productID',
            headerName: 'Fornecedor',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field:'percentage',
            headerName: 'Porcentagem',
            headerAlign: 'center',
            align: 'center',
            flex:1,
            valueGetter: (params) => `${params.value}%`
        },
        {
            field:'fee',
            headerName: 'Taxa',
            headerAlign: 'center',
            align: 'center',
            flex:1
        }
    ]

    const gridProps ={
        getRowId: (row) => row.diagnosticProductsID,
        density:'compact',
        disableSelectionOnClick: true,
        hideFooterPagination:true,
        rowsPerPageOptions:[]
    }

    return(
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant="h5" sx={{fontWeight:'bold'}} >
                    Referência de Uso
                </Typography>
            </Box>
            <Box>
                <CustomDataGrid rows={products} columns={columns} width={300} props={gridProps} />
            </Box>
        </>
    )

}

export default DiagnosticProductsGrid;