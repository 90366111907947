import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetCompanies } from '../../../../store/_Entities/Company';

import { Container } from '@mui/material';

import ResponsiveAppBar from '../../../../components/NavBar';
import Header from '../../../../components/Header';
import CompaniesGrid from '../../../../components/grids/CompaniesGrid';
import Footer from '../../../../components/Footer';
import CompanyModal from '../../../../components/CompanyModal';
import HeaderSelect from '../../../../components/HeaderSelect';

const Companies = () => {
    const { company } = useSelector((state) => state.entities);
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState(company.companies);
    const companies = company.companies;
    const [companySelect, setCompanySelect] = useState('all');
    const [statusSelect, setStatusSelect] = useState('all');

    const [selectedCompany, setSelectedCompany] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const filterData = (companyID, status) => {
        let newData = company.companies;

        if (companyID !== 'all') {
            newData = newData.filter((item) => item.companyID === companyID);
        }

        if (status !== 'all') {
            newData = newData.filter((item) => item.active === status);
        }

        setFilteredData(newData);
    };

    useEffect(() => {
        dispatch(GetCompanies());
    }, []);

    useEffect(() => {
        setFilteredData(company.companies);
    }, [company.companies]);

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Header title="Empresas" addHref={'/adm/company/add'}>
                    <HeaderSelect
                        label="Empresas"
                        defaultValue="all"
                        onChange={(event) => {
                            setCompanySelect(event.target.value);
                            filterData(event.target.value, statusSelect);
                        }}
                        menuItems={[
                            { label: 'Todas', value: 'all' },
                            ...companies.map((company) => ({
                                label: company.name,
                                value: company.companyID
                            }))
                        ]}
                    />
                    <HeaderSelect
                        label="Status"
                        defaultValue="all"
                        onChange={(event) => {
                            setStatusSelect(event.target.value);
                            filterData(companySelect, event.target.value);
                        }}
                        menuItems={[
                            { label: 'Todos', value: 'all' },
                            { label: 'Ativo', value: 1 },
                            { label: 'Inativo', value: 0 }
                        ]}
                    />
                </Header>
                <CompaniesGrid
                    data={filteredData}
                    setSelectedCompany={setSelectedCompany}
                    setShowModal={setShowModal}
                    loading={company.loading && !showModal}
                />
                <Footer />
                <CompanyModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    companyID={selectedCompany}
                />
            </Container>
        </>
    );
};

export default Companies;
