import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";

import { Box, Container, Button} from "@mui/material";

import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import {ModalWrapper} from "../Modal";

import edit from "../../assets/images/edit.png";
import checked from "../../assets/images/checked.png";



const DriverProductsGrid = ({data,setSelectedProduct,setShowModal}) => {

    const [search,setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({title:'',show:false});
    const filteredData = filterByValue(data,search);
    const sizeCol = 160;

    const showExportModalHandler = (title) => {
        setShowExportModal({title,show:true});
    }

    const gridProps = {
        getRowId: (row) => row.productID,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'productID',
            headerName: 'Código',
            headerAlign: 'center',
            align: 'center',
            minWidth:sizeCol,
            flex:1
        },
        {
            field: 'description',
            headerName: 'Nome',
            headerAlign: 'start',
            align: 'start',
            minWidth:sizeCol,
            flex:1
        },
        {
            field: 'providerID',
            headerName: 'Provedor',
            headerAlign: 'center',
            align: 'center',
            minWidth:sizeCol,
            flex:1
        },
        {
            field: 'category',
            headerName: 'Categoria',
            headerAlign: 'center',
            align: 'center',
            minWidth:sizeCol,
            flex:1
        },
        {
            field: 'actions',
            headerName: 'Editar categoria',
            headerAlign: 'center',
            align: 'center',
            minWidth:sizeCol,
            flex:1,
            renderCell: (params) => (
                <img src={edit} alt="folder" style={{width:25,cursor:'pointer'}} onClick={() => {
                    setSelectedProduct(params.row)
                    setShowModal(true)
                }} />
            )

        }
    ]

    return(
        <Container sx={{width:'100%', height:'500px'}}>
            <Box mb={10} sx={{display:`flex`, justifyContent:'space-between'}}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{
                    productID:'Código',
                    description:'Nome',
                    providerID:'Provedor',
                    category:'Categoria',
                }}/>
            </Box>
            <CustomDataGrid columns={columns} rows={filteredData} props={gridProps} />

            <ModalWrapper
                    showModal={showExportModal.show}
                    closeModalAction={() => setShowExportModal(prev => ({...prev,show:false}))}
                    title={showExportModal.title}
                    img={checked}
                    >
                    <Box>
                        <Button variant="contained" onClick={() => setShowExportModal(prev => ({...prev,show:false}))}>Ok</Button>
                    </Box>
                </ModalWrapper>
        </Container>    
    )
}

export default DriverProductsGrid;