import { Endpoints } from "../../constants/endpoints";
import { apiCallBegan } from "../api";
import { IState } from "./interfaces/SystemAdmin";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : IState = {
    loading: false,
    error: false,
    success: false,
    systemAdmins: [],
};

export const slice = createSlice({
    name:'systemAdmin',
    initialState,
    reducers:{
        USER_REQUESTED: (state, action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        USER_FAILED: (state, action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        USER_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.systemAdmins = action.payload.result;
        },
        USER_DELETE_SUCCESS: (state, action) => {
            console.log(action.payload.result);
            state.loading = false;
            state.error = false;
            state.success = true;
            state.systemAdmins = state.systemAdmins.filter(systemAdmin => systemAdmin.userID !== action.payload.result.id);
        }
    }
})

export const { USER_REQUESTED, USER_FAILED, USER_SUCCESS,USER_DELETE_SUCCESS } = slice.actions;

export default slice.reducer;

export const GetSystemAdmins = () => {
    return apiCallBegan({
        axiosConfig:{
            method: 'GET',
            url: Endpoints.SystemAdministrators.GetSystemAdministrators
        },
        onAction:{
            onStart: USER_REQUESTED.type,
            onError: USER_FAILED.type,
            onSuccess: USER_SUCCESS.type,
        }
    })
}

export const DeleteSystemAdmin = (id: string) => {
    return apiCallBegan({
        axiosConfig:{
            method: 'DELETE',
            url: Endpoints.SystemAdministrators.DeleteSystemAdministrator(id)
        },
        onAction:{
            onStart: USER_REQUESTED.type,
            onError: USER_FAILED.type,
            onSuccess: USER_DELETE_SUCCESS.type,
            showToast: true
        }
    })
}