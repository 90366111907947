import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";

import { Container, Box, Button, IconButton } from "@mui/material";
import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import { ModalWrapper } from "../Modal";
import InfoIcon from '@mui/icons-material/Info';

import checked from '../../assets/images/checked.png';
import Footer from "../Footer";

const DriverSupportGrid = ({ data }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({ title: '', show: false });
    const filteredData = filterByValue(data, search);
    const sizeCol = 200;

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    }

    const gridProps = {
        getRowId: (row) => row.wexpID,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'wexpID',
            headerName: 'WExp ID',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'registration',
            headerName: 'Matrícula',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nome',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'E-mail',
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Situação',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            headerAlign: 'center',
            align: 'center',
            minWidth: 130,
            flex: 1
        },
        {
            field: 'costCenter',
            headerName: 'Centro de Custo',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'company',
            headerName: 'Empresa',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'date',
            headerName: 'Data',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'details',
            headerName: 'Detalhes',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleDetailsClick(params.row)}>
                    <InfoIcon />
                </IconButton>
            ),
        },
    ];

    const handleDetailsClick = (row) => {
        console.log('Detalhes para:', row);
    };

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={10} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{
                    wexpID: 'WExp ID',
                    registration: 'Matrícula',
                    name: 'Nome',
                    email: 'E-mail',
                    status: 'Situação',
                    phone: 'Telefone',
                    costCenter: 'Centro de Custo',
                    company: 'Empresa',
                    date: 'Data',
                }} />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
            <Footer />

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(prev => ({ ...prev, show: false }))}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(prev => ({ ...prev, show: false }))}>Ok</Button>
                </Box>
            </ModalWrapper>
        </Container>
    )
}

export default DriverSupportGrid;
