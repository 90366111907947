import { useForm, Controller } from "react-hook-form";
import { Container, Grid, Typography, Box, TextField, Button } from "@mui/material";


const PaySettingsForm = ({data, setShowCancelModal, setShowConfirmModal}) => {

    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
        reset
    } = useForm({
        defaultValues: {
            cutoffDateWexpReports: data?.cutoffDateWexpReports || 90,
            endDateAutoSendApprovalReports: data?.endDateAutoSendApprovalReports || 90,
        },
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    const onClickSubmit = handleSubmit(onSubmit);

    return(
        <Container>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={2} sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '10px', padding: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Configurações Pay</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Customizar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="cutoffDateWexpReports"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="cutoffDateWexpReports">Data de corte para fechamento do relatório WEXP Pay</label>
                                    <Box display={'flex'} alignItems={'center'} gap={2}>
                                        <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='cutoffDateWexpReports' variant='outlined' type="number" {...field}/>
                                        <Typography variant='body2' color={'#767676'}> inserir data (dia) com dois dígitos</Typography>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="endDateAutoSendApprovalReports"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="endDateAutoSendApprovalReports">Data limite para envio automático do relatório WEXP Pay para aprovaçãoy</label>
                                    <Box display={'flex'} alignItems={'center'} gap={2}>
                                        <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='endDateAutoSendApprovalReports' variant='outlined' type="number" {...field}/>
                                        <Typography variant='body2' color={'#767676'}> inserir data (dia) com dois dígitos</Typography>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                    <Button variant='contained' type='submit'>Salvar</Button>
                    <Button variant='outlined' onClick={() => setShowCancelModal(true)}>Cancelar</Button>
                </Box>
            </form>
        </Container>
    )

}

export default PaySettingsForm;