import documentExcel from '../../../assets/images/excel.png';
import documentCsv from '../../../assets/images/csv.png';
import documentCopy from '../../../assets/images/copy.png';

import { Box, Button} from "@mui/material";

import { exportFile } from '../../../utils/download';


const ExportMenu = ({data, showExportModalHandler, headers}) => {

    const copyToClipboard = () => {
        if (data.length === 0) return;
        const keys = Object.keys(headers);
        let tableString = keys.map(key => headers[key]).join(' | ') + '\n';
    
        for (const obj of data) {
            const row = keys.map(key => obj[key]).join(' | ');
            tableString += row + '\n';
        }
    
        navigator.clipboard.writeText(tableString);
    
        showExportModalHandler('Dados copiados com sucesso');
    }

    return(
        <Box gap={2} sx={{display:'flex', alignItems:'center'}}>
            Exportar
            <Box p={'4px'} sx={{display:`flex`, boxShadow:'0px 0px 4px 0px #00000040', borderRadius:'10px'}}>
            <Button variant="text" sx={{ color: 'black', display: 'flex', alignItems: 'center', gap: '3px', textTransform: 'none' }} onClick={()=>{
                exportFile(headers,data,'xls','log_tabela')
                showExportModalHandler('Excel exportado com sucesso');
                }}>
                    <img src={documentExcel} alt="Excel" style={{width:'20px'}} />
                    <span style={{textDecoration:'underline'}}>Excel</span>
                </Button>
                <Button variant="text" sx={{ color: 'black', display: 'flex', alignItems: 'center', gap: '3px', textTransform: 'none' }} onClick={()=>{
                    exportFile(headers,data,'csv','log_tabela')
                    showExportModalHandler('Csv exportado com sucesso');
                    }}>
                    <img src={documentCsv} alt="CSV" style={{width:'20px'}} />
                    <span style={{textDecoration:'underline'}}>Csv</span>
                </Button>
                <Button variant="text" sx={{ color: 'black', display: 'flex', alignItems: 'center', gap: '3px', textTransform: 'none' }} onClick={copyToClipboard}>
                    <img src={documentCopy} alt="Copy" style={{width:'20px'}} />
                    <span style={{textDecoration:'underline'}}>Copiar</span>
                </Button>
            </Box>
        </Box>
    )
}

export default ExportMenu;