import { Box } from "@mui/material";

const CompanyMenu = ({activeItem,setActiveForm}) => {

    const menuItems = [
        "Informações da Empresa",
        "Configurações Driver",
        "Configuração Expen / KM",
        "Configuração Pay",
        "Administradores",
        "Fee de pagamento"
    ];

    return(
        <Box>
            <ul className="company-add-ul">
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        className="company-add-li"
                        style={activeItem === item ? { textDecoration: 'underline' } : {}}
                        onClick={() => setActiveForm(item)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </Box>
    );
};

export default CompanyMenu;
