// export const AuthServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
export const AuthServiceUrl =
    'https://wexp-authserver-development.azurewebsites.net';
export const wExpoGatewayUrl =
    'https://wexp-wexpogateway-development.azurewebsites.net/';
export const wExpoGatewayApiUrl = wExpoGatewayUrl + 'api';

export const Endpoints = {
    Auth: {
        ConnectToken: `${AuthServiceUrl}/connect/token`
    },
    SystemAdministrators: {
        GetSystemAdministrators: `${wExpoGatewayApiUrl}/SystemAdministrators`,
        DeleteSystemAdministrator: (userID) =>
            `${wExpoGatewayApiUrl}/SystemAdministrators/${userID}`
    },
    Company: {
        Company: `${wExpoGatewayApiUrl}/Company`,
        GetCompanyWithConfiguration: (companyID) =>
            `${wExpoGatewayApiUrl}/Company/CompanyWithConfiguration/${companyID}`,
        GetCompanyAdmin: (companyID) =>
            `${wExpoGatewayApiUrl}/Company/${companyID}/administrator`,
        DeleteCompanyAdmin: (companyID, userID) =>
            `${wExpoGatewayApiUrl}/Company/administrator/${companyID}/${userID}`
    },
    Product: {
        GetProductAdmins: `${wExpoGatewayApiUrl}/Product`
    }
};
