import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    GetSystemAdmins,
    DeleteSystemAdmin
} from '../../../../store/_Entities/SystemAdmin';

import { Container, Button } from '@mui/material';

import ResponsiveAppBar from '../../../../components/NavBar';
import Header from '../../../../components/Header';
import AdminGrid from '../../../../components/grids/AdminGrid';
import Footer from '../../../../components/Footer';

const Admins = () => {
    const { systemAdmin } = useSelector((state) => state.entities);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const dispatch = useDispatch();

    const deleteHandler = async (user) => {
        dispatch(DeleteSystemAdmin(user.userID));
    };

    useEffect(() => {
        dispatch(GetSystemAdmins());
    }, []);

    useEffect(() => {
        if (showDeleteModal && systemAdmin.success) {
            setShowDeleteModal(false);
        }
    }, [systemAdmin]);

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Header
                    title="Administradores Master"
                    addHref={'/adm/admins/add'}
                />
                <AdminGrid
                    data={systemAdmin?.systemAdmins}
                    loading={systemAdmin.loading}
                    deleteHandler={deleteHandler}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                />
                <Footer />
            </Container>
        </>
    );
};

export default Admins;
