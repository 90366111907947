import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Container, Grid, Typography, Box, Checkbox, TextField, Select, MenuItem, Button } from "@mui/material";

import { DataGrid, ptBR } from '@mui/x-data-grid';

import cancel from '../../../assets/images/cancel.png';

const radios = [
    {name:'Radio 1', token: '2942f9fj03tj2fg2f203t1'},
    {name:'Radio 2', token: '2942f9fj03tj2fg2f203t2'},
    {name:'Radio 3', token: '2942f9fj03tj2fg2f203t3'},
    {name:'Radio 4', token: '2942f9fj03tj2fg2f203t4'},
]

const DriverSettingsForm = ({data, setShowCancelModal,setShowConfirmModal}) => {

    const [selectedTaxi, setSelectedTaxi] = useState('');
    const [rows,setRows] = useState([])

    useEffect(()=>{
        if(data?.tokenRadioTaxi) {
            setRows(data?.tokenRadioTaxi.map(token => { return radios.find(radio => radio.token === token)}));
        }
    },[data?.tokenRadioTaxi, radios])

    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
        reset
    } = useForm({
        defaultValues: {
            categoryFilter: data?.categoryFilter || false,
            token99: data?.token99 || '',
            tokenUber: data?.tokenUber || '',
            tokenWappa: data?.tokenWappa || '',
            tokenRadioTaxi: data?.tokenRadioTaxi || [],
            waitingTime: data?.waitingTime || 90,
            deepLink: data?.deepLink || false,
            rideSchedule: data?.rideSchedule || false,
            voucherUber: data?.voucherUber || '',
            defaultProduct: data?.defaultProduct || '',
            nRTicketDate: data?.nRTicketDate || 90,
        },
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    const onClickSubmit = handleSubmit(onSubmit);

    useEffect(()=>{
        setValue('tokenRadioTaxi',rows.map(row => row.token));
    },[rows])

    const columns = [
        {
            field: 'name',
            headerName: 'Nome',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field: 'token',
            headerName: 'Token',
            headerAlign: 'center',
            align: 'center',
            flex:1
        },
        {
            field:'actions',
            headerName:'Deletar',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box alignItems={'center'} justifyContent={'center'} onClick={() => {
                    setRows(prev => prev.filter(row => row.token !== params.row.token));
                }}>
                    <img src={cancel} width={20} alt='delete' style={{cursor:'pointer'}}/>
                </Box>
            ),
        }
    ]

    return(
        <Container>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={2} sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '10px', padding: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Configurações Driver</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Customizar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="categoryFilter"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="categoryFilter">Filtro categorias driver </label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="categoryFilter"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Tokens</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="token99"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="token99">99</label>
                                    <TextField id='token99' variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.legalName)} />
                                    {errors?.legalName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.legalName.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="tokenUber"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="tokenUber">Uber</label>
                                    <TextField id='tokenUber' variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.legalName)} />
                                    {errors?.legalName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.legalName.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="tokenWappa"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="legalName">Wappa</label>
                                    <TextField id='legalName' variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.legalName)} />
                                    {errors?.legalName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.legalName.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                            <label htmlFor="tokenRadioTaxi">Token de radios taxis</label>
                                <Select
                                    id="tokenRadioTaxi"
                                    fullWidth
                                    onChange={(e) => setSelectedTaxi(e.target.value)}
                                    displayEmpty
                                    value={selectedTaxi}
                                    renderValue={selectedTaxi === '' ? () => 'Selecione...' : undefined}
                                    >
                                    {radios.map((radio) => (
                                        <MenuItem key={radio.token} value={radio.token}>{radio.name}</MenuItem>
                                    ))}
                                </Select>
                                    <Button sx={{textDecoration:'underline', display:'flex', alignItems:'center',gap:1}} onClick={() => {
                                        const selectedRadio = radios.find(radio => radio.token === selectedTaxi);
                                        if (selectedRadio && !rows.some(row => row.token === selectedRadio.token)) {
                                            setRows([...rows, selectedRadio]);
                                        }
                                    }}>
                                        Adicionar
                                        <img src={cancel} alt='add' style={{width:15, transform: 'rotate(45deg)'}}/>
                                    </Button>
                            </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Controller
                            name="tokenRadioTaxi"
                            control={control}
                            render={({ field }) => {
                                
                                return(
                                    <Box height={250}>
                                        <DataGrid
                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                            disableSelectionOnClick
                                            rows={rows} columns={columns} rowsPerPageOptions={[]} rowHeight={35} getRowId={(row) => row.token }
                                            sx={{
                                                '& .MuiSvgIcon-root': { color: '#EE7330'},
                                                '& .MuiDataGrid-columnHeaders': { backgroundColor: "#D9D9D9" },
                                                '& .MuiDataGrid-main': {borderRadius: '5px',boxShadow: '0px 4px 4px 0px #00000040'},
                                                '& .MuiDataGrid-iconSeparator': { display: 'none'},
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Tempo de Espera</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="waitingTime"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} gap={2} alignItems={'center'}>
                                    <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='cutoffDateWexpReports' variant='outlined' type="number" {...field}/>
                                    <Typography variant='body2' color={'#767676'}>Selecione a quantidade de dias para a troca de senha</Typography>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="deepLink"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="deeplink">Deeplink </label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="deeplink"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Controller
                            name="rideSchedule"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="rideSchedule">Agendamento de corrida </label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="rideSchedule"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="voucherUber"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="voucherUber">Voucher Uber</label>
                                    <Select
                                        fullWidth
                                        id='voucherUber'
                                        size='small'
                                        value={field.value}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        displayEmpty
                                        renderValue={field.value !== "" ? undefined : () => 'Selecione...'}
                                    >
                                        {radios.map((radio) => (
                                            <MenuItem key={radio.token} value={radio.token}>{radio.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {errors?.voucherUber && (
                                        <Typography variant="caption" color="error">
                                            {errors?.voucherUber.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="defaultProduct"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="defaultProduct">Produto Default</label>
                                    <Select
                                        fullWidth
                                        id='defaultProduct'
                                        size='small'
                                        value={field.value}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        displayEmpty
                                        renderValue={field.value !== "" ? undefined : () => 'Selecione...'}
                                    >
                                        {radios.map((radio) => (
                                            <MenuItem key={radio.token} value={radio.token}>{radio.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {errors?.voucherUber && (
                                        <Typography variant="caption" color="error">
                                            {errors?.voucherUber.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Data de Corte Ticket NR</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="nRTicketDate"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} gap={2} alignItems={'center'}>
                                    <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='cutoffDateWexpReports' variant='outlined' type="number" {...field}/>
                                    <Typography variant='body2' color={'#767676'}>Inserir data (dia) com dois digitos</Typography>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                    <Button variant='contained' type='submit'>Salvar</Button>
                    <Button variant='outlined' onClick={() => setShowCancelModal(true)}>Cancelar</Button>
                </Box>
            </form>
        </Container>
    )
};

export default DriverSettingsForm;