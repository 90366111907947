import { useState } from "react";
import { filterByValue } from "../../utils/filterGrid";

import { Box, Button, Container, Typography } from "@mui/material";

import SearchBar from "./components/SearchBar";
import CustomDataGrid from "./components/CustomDataGrid";

import documentExcel from '../../assets/images/excel.png';
import share from '../../assets/images/share.png';


const DriverGrid = ({data, setSelectedCompany, setShowErrorsModal}) => {

    const [search, setSearch] = useState('');
    const filteredData = filterByValue(data, search);
    const sizeCol = 160;

    const gridProps = {
        getRowId: (row) => row.id,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'company',
            headerName: 'Empresa',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'user',
            headerName: 'Usuário',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'created',
            headerName: 'Criado',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field:'actions',
            headerName:'Erro',
            headerAlign:'center',
            align:'center',
            minWidth: sizeCol,
            flex:1,
            renderCell: (params) => (
                <Box display={'flex'} alignItems={'center'} gap={1} sx={{cursor:'pointer'}} onClick={()=>
                    {
                        setShowErrorsModal(true)
                        setSelectedCompany(params.row)
                    }}>
                    <Typography variant="body" color={'primary'} sx={{textDecoration:'underline'}}>
                        Visualizar
                    </Typography>
                    <img src={share} alt="Info" style={{width:'20px'}} />
                </Box>
            )
        }
    ]

    return(
        <Container sx={{width:'100%', height:'500px'}}>
            <Box mb={10} sx={{display:`flex`, justifyContent:'space-between'}}>
                <SearchBar search={search} setSearch={setSearch} />
                <Box display={'flex'} alignItems={'center'} gap={2}>
                    Upload
                <Box p={'4px'} sx={{display:`flex`, boxShadow:'0px 0px 4px 0px #00000040', borderRadius:'10px'}}>
                    <Button variant="text" sx={{ color: 'black', display: 'flex', alignItems: 'center', gap: '3px', textTransform: 'none' }} onClick={() => console.log('Upload arquivo')}>
                        <img src={documentExcel} alt="Excel" style={{width:'20px'}} />
                        <span style={{textDecoration:'underline'}}>Selecione um Arquivo</span>
                    </Button>
                </Box>
                    <Button variant="contained" onClick={()=> console.log('Cadastrar Lote')}>
                        Cadastrar Lote
                    </Button>
                </Box>
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
        </Container>
    )
    


}

export default DriverGrid;