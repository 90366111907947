import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import checked from "../../assets/images/checked.png";
import Footer from "../../components/Footer";
import { ModalWrapper } from "../../components/Modal";
import ResponsiveAppBar from "../../components/NavBar";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; 

const mockCompanies = [
  { id: 1, name: "ABBOTT - ARDX" },
  { id: 2, name: "Empresa B" },
  { id: 3, name: "Empresa C" },
];

const mockVerticals = [
  { id: 1, name: "Driver" },
  { id: 2, name: "Economy" },
  { id: 3, name: "Premium" },
];

const mockCollectors = [
  { id: 1, name: "Wexp" },
  { id: 2, name: "Uber" },
];

const Invoicing = () => {
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const form = useForm({
    defaultValues: {
      collectorCompany: '',
      startDate: '',
      endDate: '',
      billedCompany: '',
      vertical: '',
      serviceValue: '',
      tpuValue: '',
      taxIncrease: '',
      discount: '',
    },
    mode: 'onBlur',
  });

  const { handleSubmit } = form;

  const onSubmit = (data) => {
    console.log("Dados Enviados:", data);
    setShowModal(true);
  };

  const nextStep = () => setFormStep((prev) => prev + 1);
  const prevStep = () => setFormStep((prev) => prev - 1);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box xs={12} py={1} mb="10px">
          <h2>Configurações de Faturamento</h2>
        </Box>

        <Stepper activeStep={formStep} alternativeLabel sx={{
          '& .MuiStepIcon-root': { fontSize: '2.5rem', zIndex: 1 },
          '& .MuiStepConnector-root': {
            top: '1.25rem', width: 'calc(100% - 100px)',
            marginLeft: 'calc(50% - 160px)',
          },
        }}>
          <Step><StepLabel>Dados da Empresa</StepLabel></Step>
          <Step><StepLabel>Dados de Faturamento</StepLabel></Step>
          <Step><StepLabel>Espelho da Fatura</StepLabel></Step>
        </Stepper>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            {formStep === 0 && <StepCompany nextStep={nextStep} />}
            {formStep === 1 && <StepInvoice nextStep={nextStep} prevStep={prevStep} />}
            {formStep === 2 && <StepMirror prevStep={prevStep} />}
          </FormProvider>
        </form>

        <Footer />

        <ModalWrapper
          showModal={showModal}
          closeModalAction={() => navigate("/adm/invoicing")}
          title="Configuração Salva com Sucesso"
          img={checked}
        >
          <Box>
            <Button variant="contained" onClick={() => navigate("/adm/invoicing")}>Ok</Button>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

// Etapa 1: Dados da Empresa
const StepCompany = ({ nextStep }) => {
  const { control, formState: { errors }, trigger } = useFormContext();

  const handleNext = async () => {
    const valid = await trigger(["startDate", "endDate", "billedCompany", "collectorCompany", "vertical"]);
    if (valid) nextStep();
  };

  return (
    <Box mt={5} p={5} boxShadow="0px 0px 4px 0px #00000040" borderRadius={2}>
      <Typography variant="h5">Dados da Empresa</Typography>

      <Box display="flex" flexDirection="column" gap={3} mt={4}>
        <Controller
          name="collectorCompany"
          control={control}
          rules={{ required: "Selecione uma empresa arrecadante" }}
          render={({ field }) => (
            <>
              <label>Empresa Arrecadante</label>
              <Select {...field} fullWidth displayEmpty>
                <MenuItem value="" disabled>Selecione...</MenuItem>
                {mockCollectors.map((collector) => (
                  <MenuItem key={collector.id} value={collector.name}>
                    {collector.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />

        <Box display="flex" gap={2}>
          <Controller
            name="startDate"
            control={control}
            rules={{ required: "Informe a data inicial" }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label="De"
                  value={field.value ? dayjs(field.value) : null}
                  onChange={(date) => field.onChange(date ? date.toISOString() : '')}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={Boolean(errors.startDate)} 
                      helperText={errors.startDate?.message} 
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />

          <Controller
            name="endDate"
            control={control}
            rules={{ required: "Informe a data final" }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label="Até"
                  value={field.value ? dayjs(field.value) : null}
                  onChange={(date) => field.onChange(date ? date.toISOString() : '')}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={Boolean(errors.endDate)} 
                      helperText={errors.endDate?.message} 
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Box>

        <Controller
          name="billedCompany"
          control={control}
          rules={{ required: "Selecione uma empresa cobrada" }}
          render={({ field }) => (
            <>
              <label>Empresa Cobrada</label>
              <Select {...field} fullWidth displayEmpty>
                <MenuItem value="" disabled>Selecione...</MenuItem>
                {mockCompanies.map((company) => (
                  <MenuItem key={company.id} value={company.name}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />

        <Controller
          name="vertical"
          control={control}
          rules={{ required: "Selecione uma vertical" }}
          render={({ field }) => (
            <>
              <label>Vertical</label>
              <Select {...field} fullWidth displayEmpty>
                <MenuItem value="" disabled>Selecione a Vertical...</MenuItem>
                {mockVerticals.map((vertical) => (
                  <MenuItem key={vertical.id} value={vertical.name}>
                    {vertical.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </Box>

      <Box display="flex" justifyContent="end" mt={2}>
        <Button variant="contained" onClick={handleNext}>Próximo</Button>
      </Box>
    </Box>
  );
};


// Etapa 2: Dados de Faturamento

const StepInvoice = ({ nextStep, prevStep }) => {
  const { control, formState: { errors }, trigger } = useFormContext();

  const handleNext = async () => {
    const valid = await trigger(["serviceValue", "tpuValue", "taxIncrease", "discount"]);
    if (valid) nextStep();
  };

  return (
    <Box mt={5} p={5} boxShadow="0px 0px 4px 0px #00000040" borderRadius={2}>
  <Typography variant="h5">Dados de Faturamento</Typography>

  <Box display="flex" flexDirection="column" gap={3}>
    {[
      { name: "serviceValue", label: "Valor do Serviço" },
      { name: "tpuValue", label: "Valor do Consumo TPU" },
      { name: "taxIncrease", label: "Acréscimo de Imposto" },
      { name: "discount", label: "Desconto" }
    ].map(({ name, label }) => (
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field }) => (
          <NumericFormat
            {...field}
            fullWidth
            label={label}
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            error={Boolean(errors[name])}
            helperText={errors[name]?.message}
          />
        )}
      />
    ))}
  </Box>

  <Box display="flex" justifyContent="space-between" mt={2}>
    <Button variant="outlined" onClick={prevStep}>Voltar</Button>
    <Button variant="contained" onClick={handleNext}>Próximo</Button>
  </Box>
</Box>

  );
};


//etapa 3:
const StepMirror = ({ prevStep }) => {
  const { getValues } = useFormContext();
  const values = getValues();

  return (
    <Box mt={5} p={5} boxShadow="0px 0px 4px 0px #00000040" borderRadius={2}>
      <Typography variant="h5">Espelho da Fatura</Typography>

      <Box mt={3}>
        <Typography variant="h6">Resumo dos Dados da Empresa:</Typography>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          <Typography><strong>Empresa Arrecadante:</strong> {values.collectorCompany}</Typography>
          <Typography><strong>Empresa Cobrada:</strong> {values.billedCompany}</Typography>
          <Typography><strong>Vertical:</strong> {values.vertical}</Typography>
          <Typography><strong>Data Inicial:</strong> {values.startDate}</Typography>
          <Typography><strong>Data Final:</strong> {values.endDate}</Typography>
        </Box>

        <Typography variant="h6" mt={3}>Resumo dos Dados de Faturamento:</Typography>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          <Typography><strong>Valor do Serviço:</strong> R$ {values.serviceValue}</Typography>
          <Typography><strong>Valor TPU:</strong> R$ {values.tpuValue}</Typography>
          <Typography><strong>Acréscimo de Taxa:</strong> R$ {values.taxIncrease}</Typography>
          <Typography><strong>Desconto:</strong> R$ {values.discount}</Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button variant="outlined" onClick={prevStep}>Voltar</Button>
        <Button variant="contained" type="submit">Salvar</Button>
      </Box>
    </Box>
  );
};

export default Invoicing;