import { Endpoints } from "../../constants/endpoints";
import { apiCallBegan } from "../api";
import { IState } from "./interfaces/Product";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : IState = {
    loading: false,
    error: false,
    success: false,
    productAdmins: [],
};

export const slice = createSlice({
    name:'productAdmin',
    initialState,
    reducers:{
        PRODUCT_REQUESTED: (state, action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        PRODUCT_FAILED: (state, action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        PRODUCT_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.productAdmins = action.payload.result;
        },
    }
})

export const { PRODUCT_REQUESTED, PRODUCT_FAILED, PRODUCT_SUCCESS} = slice.actions;

export default slice.reducer;

export const GetProductAdmins = () => {
    return apiCallBegan({
        axiosConfig:{
            method: 'GET',
            url: Endpoints.Product.GetProductAdmins
        },
        onAction:{
            onStart: PRODUCT_REQUESTED.type,
            onError: PRODUCT_FAILED.type,
            onSuccess: PRODUCT_SUCCESS.type,
        }
    })
}