import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";

import { Box, Container } from "@mui/material";

import SearchBar from "./components/SearchBar";
import CustomDataGrid from "./components/CustomDataGrid";

import edit from '../../assets/images/edit.png';
import download from '../../assets/images/download.png';


const SftpSettingsGrid = ({data, setSelectedItem,setShowEditModal}) => {
    const [search,setSearch] = useState('');
    const filteredData = filterByValue(data,search);
    const sizeCol = 160;

    const gridProps = {
        getRowId: (row) => row.id,
        disableSelectionOnClick: true,
    }

    const columns = [
        {
            field: 'user',
            headerName: 'Usuário',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'sshKey',
            headerName: 'Chave SSH',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'secretPhrase',
            headerName: 'Frase Secreta',
            headerAlign: 'center',
            align: 'center',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'edit',
            headerName: 'Editar',
            headerAlign: 'center',
            align: 'center',
            minWidth: sizeCol,
            flex:1,
            renderCell: (params) => <img src={edit} style={{width:25,cursor:'pointer'}} onClick={() => {
                setSelectedItem(params.row);
                setShowEditModal(true);
            }}/>
        },
        {
            field: 'download',
            headerName: 'Download PublicKey',
            headerAlign: 'center',
            align: 'center',
            minWidth: sizeCol,
            flex:1,
            renderCell: (params) => <img src={download} style={{width:25,cursor:'pointer'}} onClick={() => console.log('Download')}/>
        }
    ]

    return(
        <Container sx={{width:'100%', height:'500px'}}>
            <Box mb={10} sx={{display:`flex`, justifyContent:'space-between'}}>
                <SearchBar search={search} setSearch={setSearch} />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps}/>
        </Container>
    )


}

export default SftpSettingsGrid;