// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState } from './interfaces/User';
import { ILoginDto } from './interfaces/User';
import { Endpoints } from '../../constants/endpoints';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import parseJwt from '../../utils/parseJwt';
import { IAccountHolder } from './interfaces/AccountHolder';
import getAttributeContains from '../../utils/getAttributeContains';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    isLoggedIn: false,
    loginDate: new Date(),
    access_token: null,
    validCaptcha: false,
};

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        USER_REQUESTED: (user: IState, action: PayloadAction<IState>) => {
            user.loading = true;
            user.error = false;
            user.success = false;
        },

        USER_FAILED: (user: IState, action: PayloadAction<IState>) => {
            user.loading = false;
            user.error = true;
            user.success = false;
            user.validCaptcha = false;
        },

        USER_LOGGED_IN_SUCCESSFULLY: (
            user: IState,
            action: PayloadAction<any>
        ) => {
            const { access_token, refresh_token } = action.payload;

            user.loading = false;
            user.error = false;
            user.success = true;
            user.isLoggedIn = true;
            user.access_token = access_token;
            const authData = parseJwt(access_token);
            user.authData = authData;
            user.loginDate = new Date();


            // http://schemas.microsoft.com/ws/2008/06/identity/claims/role
            const roles = getAttributeContains(authData, 'claims/role');
            user.roles = roles;

            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            user.validCaptcha = false;
        },
        USER_LOGOUT: (user: IState, action: PayloadAction<IState>) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            user.isLoggedIn = false;
            user.access_token = null;
            user.authData = null;
            user.data = undefined;
            user.loading = false;
        },
        REFRESH_TOKEN: (user: IState, action: PayloadAction<any>) => {
            const { access_token, refresh_token } = action.payload;
            user.access_token = access_token;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            user.authData = parseJwt(access_token);
        },
        REFRESH_REQUESTED: (user: IState, action: PayloadAction<IState>) => {
            console.log('refresh requested');
        },
        GET_USER_BY_ID_SUCCESSFULLY: (
            user: IState,
            action: PayloadAction<IRequestResponse<IAccountHolder>>
        ) => {
            user.data = action.payload.result;
        },
    }
});

export const {
    USER_REQUESTED,
    USER_FAILED,
    USER_LOGGED_IN_SUCCESSFULLY,
    USER_LOGOUT,
    REFRESH_TOKEN,
    REFRESH_REQUESTED,
    GET_USER_BY_ID_SUCCESSFULLY,
} = slice.actions;

export default slice.reducer;

export const loginUser = (LoginDto: ILoginDto) => {
    const formData = new FormData();

    formData.append('grant_type', 'password');
    formData.append('client_id', 'portalv2');
    formData.append('client_secret', 'secret');
    formData.append('username', LoginDto.email);
    formData.append('password', LoginDto.password);
    formData.append('scope', 'wExpoPublicAPI wExpoExpensesPublicAPI');

    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Auth.ConnectToken,
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-encoded'
            },
            data: formData
        },
        onAction: {
            onStart: USER_REQUESTED.type,
            onSuccess: USER_LOGGED_IN_SUCCESSFULLY.type,
            onError: USER_FAILED.type,
        }
    };
    return apiCallBegan(request);
};

export const refreshToken = () => {
    const formData = new FormData();

    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) return;

    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', 'portalv2');
    formData.append('client_secret', 'secret');
    formData.append('refresh_token', refreshToken);
    formData.append('scope', 'wExpoPublicAPI wExpoExpensesPublicAPI');

    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Auth.ConnectToken,
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-encoded'
            },
            data: formData
        },
        onAction: {
            onStart: REFRESH_REQUESTED.type,
            onSuccess: REFRESH_TOKEN.type,
            onError: USER_FAILED.type
        }
    };
    return apiCallBegan(request);
};