import { useState } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Box } from '@mui/material';

export const CustomDataGrid = ({rows,columns,width=400,props}) => {

  const [pageSize, setPageSize] = useState(25);

    return(
      <Box height={width}>
        <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25,50,100]}
                rowHeight={60}
                {...props}
                sx={{
                    '& .MuiSvgIcon-root': { color: '#EE7330'},
                    '& .MuiDataGrid-columnHeaders': { backgroundColor: "#D9D9D9" },
                    '& .MuiDataGrid-main': {borderRadius: '5px',boxShadow: '0px 4px 4px 0px #00000040'},
                    '& .MuiDataGrid-iconSeparator': { display: 'none'},
                }}
                />
        </Box>
    )
}

export default CustomDataGrid;