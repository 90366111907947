import { useState } from "react";

import { filterByValue } from "../../utils/filterGrid";
import { MaskCnpj } from "../../utils/mask";

import { Box, Button, Container, Checkbox } from "@mui/material";

import CustomDataGrid from "./components/CustomDataGrid";
import ExportMenu from "./components/ExportMenu";
import SearchBar from "./components/SearchBar";
import { ModalWrapper} from "../Modal";

import checked from '../../assets/images/checked.png';
import edit from '../../assets/images/edit.svg';
import infoOrange from '../../assets/images/info-orange.png';
import adm from '../../assets/images/adm.svg';
import { useNavigate } from "react-router-dom";


const CompaniesGrid = ({data, setSelectedCompany, setShowModal,loading}) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({title:'',show:false});
    const filteredData = filterByValue(data, search);
    const sizeCol = 160;

    const navigate = useNavigate();

    const gridProps = {
        getRowId: (row) => row.companyID,
        disableSelectionOnClick: true,
        loading:loading
    }

    const showExportModalHandler = (title) => {
        setShowExportModal({title,show:true});
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Empresa',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'giveName',
            headerName: 'Nome Fantasia',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex:1
        },
        {
            field: 'state',
            headerName: 'Estado',
            headerAlign: 'start',
            align: 'center',
            minWidth: 20,
            flex:1
        },
        {
            field: 'document',
            headerName: 'CNPJ',
            headerAlign: 'start',
            align: 'start',
            minWidth: 100,
            flex:1,
            valueGetter: (params) => MaskCnpj(params.value)
        },
        {
            field: 'active',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            flex:1,
            renderCell: (params) => <Checkbox checked={params.value === 1 ? true: false} />
        },
        {
            field: "Edit",
            headerName: "Editar",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (cellValues) => {
              return (
                <img
                    onClick={() => navigate(`/adm/company/edit/${cellValues.row.companyID}`)}
                    src={edit}
                    width={20}
                    style={{cursor:'pointer'}}
                />
              );
            }
          },
          {
            field: "adm",
            headerName: "Adm",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (cellValues) => {
              return (
                <img
                    onClick={() => navigate(`/adm/company/admins/${cellValues.row.companyID}`)}
                    src={adm}
                    width={20}
                    style={{cursor:'pointer'}}
                />
              );
            }
          },
          {
            field: "info",
            headerName: "Info Rápida",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (cellValues) => {
              return (
                <img
                    onClick={() => {
                        setSelectedCompany(cellValues.row.companyID);
                        setShowModal(true);
                    }}
                    src={infoOrange}
                    width={20}
                    style={{cursor:'pointer'}}
                />
              );
            }
          },
    ]

    return(
        <Container sx={{width:'100%', height:'500px'}}>
            <Box mb={10} sx={{display:`flex`, justifyContent:'space-between'}}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu data={filteredData} showExportModalHandler={showExportModalHandler} headers={{name:"Empresa",giveName:"Nome Fantasia",state:"Estado",document:"CNPJ",active:"Status"}}  />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps}/>

            <ModalWrapper
                    showModal={showExportModal.show}
                    closeModalAction={() => setShowExportModal(false)}
                    title={showExportModal.title}
                    img={checked}
                    >
                    <Box>
                        <Button variant="contained" onClick={() => setShowExportModal(false)}>Ok</Button>
                    </Box>
                </ModalWrapper>
        </Container>
    )
}

export default CompaniesGrid;