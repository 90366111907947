import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Container, Grid, Button } from '@mui/material';

import ResponsiveAppBar from '../../../../components/NavBar';
import CompanyMenu from '../../../../components/CompanyMenu';
import { ModalWrapper } from '../../../../components/Modal';
import Footer from '../../../../components/Footer';

import CompanyInfoForm from '../../../../components/forms/company/CompanyInfoForm';
import DriverSettingsForm from '../../../../components/forms/company/DriverSettingsForm';
import ExpenSettingsForm from '../../../../components/forms/company/ExpenSettingsForm';
import PaySettingsForm from '../../../../components/forms/company/PaySettingsForm';
import FeeSettingsForm from '../../../../components/forms/company/FeeSettingsForm';

import alerta from '../../../../assets/images/alerta.png';
import checked from '../../../../assets/images/checked.png';

const mockCompanyInfo = {
    logo: 'https://via.placeholder.com/150',
    city: 'São Paulo',
    cnpj: '1234567890',
    legalName: 'Empresa Teste',
    fantasyName: 'Fantasy Empresa Teste',
    state: 'SP',
    street: 'Rua Teste',
    number: '123',
    neighborhood: 'Bairro Teste',
    complement: 'Complemento Teste',
    project: true,
    reason: false,
    justification: true,
    driver: true,
    expen: true,
    km: false,
    pay: false,
    sso: true,
    ssoRule: 2,
    resetPassword: 30
};

const mockDriverSettings = {
    categoryFilter: true,
    token99: 'token99',
    tokenUber: 'tokenUber',
    tokenWappa: 'tokenWappa',
    tokenRadioTaxi: ['2942f9fj03tj2fg2f203t1', '2942f9fj03tj2fg2f203t3'],
    waitingTime: 30,
    deepLink: true,
    rideSchedule: false,
    voucherUber: '2942f9fj03tj2fg2f203t4',
    defaultProduct: '2942f9fj03tj2fg2f203t3',
    nRTicketDate: 30
};

const mockExpenSettings = {
    internalKm: true,
    defaultKmValue: '2.50',
    defaultKm: 2,
    blockReportSending: true,
    openAdvancePeriod: 15
};

const mockPaySettings = {
    cutoffDateWexpReports: 15,
    endDateAutoSendApprovalReports: 20
};

const mockFeeSettings = {
    driverEconomy: { active: true, value: 10 },
    driverTransaction: { active: true, value: 11 },
    driverUser: { active: true, value: 12 },
    driverFixedValue: { active: true, value: 13 },
    expenEconomy: { active: true, value: 14 },
    expenUser: { active: false, value: 0 },
    expenFixedValue: { active: true, value: 15 },
    kmEconomy: { active: true, value: 16 },
    kmTransaction: { active: false, value: 0 },
    kmUser: { active: true, value: 17 },
    kmFixedValue: { active: true, value: 18 }
};

const CompanySettings = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const formParam = query.get('form');

    const [activeForm, setActiveForm] = useState(
        formParam || 'Informações da Empresa'
    );
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const formProps = {
        data: {},
        setShowCancelModal,
        setShowConfirmModal
    };

    useEffect(() => {
        query.set('form', activeForm);
        navigate(`${location.pathname}?${query.toString()}`, { replace: true });
    }, [activeForm]);

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <h2>Configuração</h2>
                <Grid container rowSpacing={4}>
                    <Grid item xs={12} md={3}>
                        <CompanyMenu
                            activeItem={activeForm}
                            setActiveForm={setActiveForm}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box>
                            {activeForm === 'Informações da Empresa' && (
                                <CompanyInfoForm {...formProps} />
                            )}
                            {activeForm === 'Configurações Driver' && (
                                <DriverSettingsForm {...formProps} />
                            )}
                            {activeForm === 'Configuração Expen / KM' && (
                                <ExpenSettingsForm {...formProps} />
                            )}
                            {activeForm === 'Configuração Pay' && (
                                <PaySettingsForm {...formProps} />
                            )}
                            {activeForm === 'Fee de pagamento' && (
                                <FeeSettingsForm {...formProps} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Footer />

                <ModalWrapper
                    showModal={showCancelModal}
                    closeModalAction={() => setShowCancelModal(false)}
                    title="Deseja Cancelar?"
                    img={alerta}
                >
                    <p>As informações não serão salvas</p>
                    <Box sx={{ display: 'flex', width: '100%', gap: 4 }}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/adm/companies')}
                        >
                            Confirmar
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setShowCancelModal(false)}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </ModalWrapper>
                <ModalWrapper
                    showModal={showConfirmModal}
                    closeModalAction={() => setShowConfirmModal(false)}
                    title={'Configuração salva com sucesso'}
                    img={checked}
                >
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => setShowConfirmModal(false)}
                        >
                            Ok
                        </Button>
                    </Box>
                </ModalWrapper>
            </Container>
        </>
    );
};

export default CompanySettings;
