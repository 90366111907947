import React from "react";
import { useForm, FormProvider, Controller, useFormContext } from "react-hook-form";
import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";

const mockContent = [
    { id: 1, company: 'Empresa 1' },
    { id: 2, company: 'Empresa 2' },
    { id: 3, company: 'Empresa 3' },
    { id: 4, company: 'Empresa 4' },
    { id: 5, company: 'Empresa 5' },
    { id: 6, company: 'Empresa 6' },
    { id: 7, company: 'Empresa 7' },
];

const SftpStep1 = ({ nextStep }) => {
    const { control, formState: { errors }, trigger } = useFormContext();

    const handleNextStep = async () => {
        const result = await trigger(["company", "voucherName"]);
        if (result) {
            nextStep();
        }
    };

    return (
        <>
            <Box mt={5} minHeight={200} boxShadow={'0px 0px 4px 0px #00000040'} p={5} borderRadius={2} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                <Box>
                    <h3>Adicionar novo Voucher</h3>
                </Box>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={4}>
                    <Box flex={1} maxWidth={400} display={'flex'} flexDirection={'column'} gap={2}>
                        <label htmlFor="company">Empresa</label>
                        <Controller
                            name="company"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        labelId="company-select-label"
                                        id="company"
                                        displayEmpty
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        fullWidth
                                        renderValue={(value) => value ? mockContent.find(item => item.id === value).company : 'Selecione...'}
                                        error={Boolean(errors?.company)}
                                    >
                                        {mockContent.map((item) => <MenuItem key={item.id} value={item.id}>{item.company}</MenuItem>)}
                                    </Select>
                                    {errors?.company && (
                                        <Typography variant="caption" color="error">
                                            {errors?.company.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                    <Box flex={1} maxWidth={400} display={'flex'} flexDirection={'column'} gap={2}>
                        <Controller
                            name="voucherName"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <>
                                    <label htmlFor="voucherName">Nome Voucher</label>
                                    <TextField {...field} variant="outlined" placeholder="Digite..." fullWidth error={Boolean(errors?.voucherName)} />
                                    {errors?.voucherName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.voucherName.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
                <Button variant='outlined'>Cancelar</Button>
                <Button variant='contained' onClick={handleNextStep}>Próximo</Button>
            </Box>
        </>
    );
};

export default SftpStep1;