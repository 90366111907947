import { useState } from 'react';
import { Container, Grid, Box, Button } from '@mui/material';

import ResponsiveAppBar from '../../components/NavBar';
import InvoiceFilesGrid from '../../components/grids/invoiceFilesGrid';
import Footer from '../../components/Footer';
import { ModalWrapper } from '../../components/Modal';
import HeaderSelect from '../../components/HeaderSelect';

const mockCompanies = [
    { companyID: 1, name: 'Empresa A' },
    { companyID: 2, name: 'Empresa B' },
    { companyID: 3, name: 'Empresa C' }
];

const mockInvoices = [
    {
        billingID: '001',
        companyID: 1,
        company: 'Empresa A',
        createdAt: '2024-10-01',
        updatedAt: '2024-10-10',
        paymentDate: '2024-10-15',
        amount: 1000,
        startDate: '2024-09-01',
        endDate: '2024-09-30',
        qtdWEXP: 10,
        qtdUber: 5,
        purchaseOrder: 'PO12345',
        invoiceNumber: 'NF-001',
        status: 1
    },
    {
        billingID: '002',
        companyID: 2,
        company: 'Empresa B',
        createdAt: '2024-10-02',
        updatedAt: '2024-10-11',
        paymentDate: '2024-10-16',
        amount: 2000,
        startDate: '2024-09-01',
        endDate: '2024-09-30',
        qtdWEXP: 15,
        qtdUber: 8,
        purchaseOrder: 'PO67890',
        invoiceNumber: 'NF-002',
        status: 2
    }
];

const invoicesWithId = mockInvoices.map((invoice) => ({
    ...invoice,
    id: invoice.billingID
}));

const InvoiceFiles = () => {
    const [data, setData] = useState(invoicesWithId);
    const [companySelect, setCompanySelect] = useState('all');
    const [statusSelect, setStatusSelect] = useState('all');
    const [showModal, setShowModal] = useState(false);

    const filterInvoices = (companyID, status) => {
        let filteredData = invoicesWithId;

        if (companyID !== 'all') {
            filteredData = filteredData.filter((invoice) => invoice.companyID === parseInt(companyID));
        }

        if (status !== 'all') {
            filteredData = filteredData.filter((invoice) => invoice.status === parseInt(status));
        }

        setData(filteredData);
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Grid container>
                    <Grid item xs={12} py={1} sx={{ marginBottom: '10px', borderBottom: '1px solid black' }}>
                        <h2>Faturas</h2>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 4 }}>
                            <HeaderSelect
                                label="Empresas"
                                defaultValue="all"
                                onChange={(event) => {
                                    setCompanySelect(event.target.value);
                                    filterInvoices(event.target.value, statusSelect);
                                }}
                                menuItems={[
                                    { label: 'Todas', value: 'all' },
                                    ...mockCompanies.map((company) => ({
                                        label: company.name,
                                        value: company.companyID
                                    }))
                                ]}
                            />
                            <HeaderSelect
                                label="Status"
                                defaultValue="all"
                                onChange={(event) => {
                                    setStatusSelect(event.target.value);
                                    filterInvoices(companySelect, event.target.value);
                                }}
                                menuItems={[
                                    { label: 'Todos', value: 'all' },
                                    { label: 'Status 1', value: 1 },
                                    { label: 'Status 2', value: 2 },
                                    { label: 'Status 3', value: 3 }
                                ]}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <InvoiceFilesGrid data={data} loading={false} />

                <Footer />

                <ModalWrapper
                    showModal={showModal}
                    closeModalAction={() => setShowModal(false)}
                    title="Upload de Nota Fiscal"
                >
                    <Box display="flex" flexDirection="column" gap={3}>
                        <input type="file" />
                        <Box display="flex" gap={2} justifyContent="end">
                            <Button variant="outlined" onClick={() => setShowModal(false)}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => setShowModal(false)}>
                                Salvar
                            </Button>
                        </Box>
                    </Box>
                </ModalWrapper>
            </Container>
        </>
    );
};

export default InvoiceFiles;
