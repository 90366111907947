import { useForm, Controller } from "react-hook-form";
import { Container, Grid, Typography, Checkbox, Select, Box, TextField, MenuItem, Button } from "@mui/material";
import InputWithMask from "../../InputWithMask";
import { float2pointMask } from "../../../utils/inputMasks";

const defaultKm = [
    {
        id: 1, label: 'Cálculo 1',
    },
    {
        id: 2, label: 'Cálculo 2',
    },
    {
        id: 3, label: 'Cálculo 3',
    },
    {
        id: 4, label: 'Cálculo 4',
    }
]

const ExpenSettingsForm = ({data, setShowCancelModal,setShowConfirmModal}) => {

    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
        reset
    } = useForm({
        defaultValues: {
            internalKm: data?.internalKm || false,
            defaultKmValue: data?.defaultKmValue || '',
            defaultKm: data?.defaultKm || '',
            blockReportSending: data?.blockReportSending || false,
            openAdvancePeriod: data?.openAdvancePeriod || 90,
        },
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    const onClickSubmit = handleSubmit(onSubmit);

    return(
        <Container>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={2} sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '10px', padding: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Configurações Expen / KM</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Customizar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="internalKm"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="internalKm">Km Interno </label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="internalKm"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>KM</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="defaultKmValue"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="defaultKmValue">Valor KM Padrão</label>
                                    <InputWithMask
                                        variant="outlined"
                                        placeholder='Digite...'
                                        fullWidth
                                        mask={float2pointMask}
                                        {...field}
                                        error={Boolean(errors?.defaultKmValue)} 
                                    />
                                    {errors?.defaultKmValue && (
                                        <Typography variant="caption" color="error">
                                            {errors?.defaultKmValue.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="defaultKm"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="defaultKm">Cálculo KM Default</label>
                                    <Select
                                        id="defaultKm"
                                        fullWidth
                                        value={field.value}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        displayEmpty
                                        renderValue={field.value !== "" ? undefined : () => 'Selecione...'}
                                    >
                                        {defaultKm.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                    {errors?.defaultKm && (
                                        <Typography variant="caption" color="error">
                                            {errors?.defaultKm.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="blockReportSending"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="blockReportSending">Bloquear envio de relatório </label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="blockReportSending"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Período de adiantamentos abertos</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="openAdvancePeriod"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="openAdvancePeriod">Cálculo KM Default</label>
                                    <Box>
                                        <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='cutoffDateWexpReports' variant='outlined' type="number" {...field}/>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                    <Button variant='contained' type='submit'>Salvar</Button>
                    <Button variant='outlined' onClick={() => setShowCancelModal(true)}>Cancelar</Button>
                </Box>
            </form>
        </Container>
    )

}

export default ExpenSettingsForm;