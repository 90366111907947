import { useState } from 'react';
import { Container, Box, Button, IconButton } from '@mui/material';
import { CloudUpload, Replay, AttachFile, Download } from '@mui/icons-material';
import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';
import CustomDataGrid from './components/CustomDataGrid';
import { filterByValue } from '../../utils/filterGrid';
import { ModalWrapper } from '../Modal';
import checked from '../../assets/images/checked.png';

const InvoiceFilesGrid = ({ data, loading }) => {
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const sizeCol = 180;

  const filteredData = filterByValue(data, search);

  const handleDownload = (file) => {
    console.log(`Baixando arquivo: ${file}`);
    // quem for integrar a tela incluir a Lógica para download do arquivo
  };

  const columns = [
    { field: 'billingID', headerName: 'ID do Billing', minWidth: sizeCol, flex: 1 },
    { field: 'company', headerName: 'Empresa', minWidth: sizeCol, flex: 1 },
    { field: 'createdAt', headerName: 'Data de Criação', minWidth: sizeCol, flex: 1 },
    { field: 'updatedAt', headerName: 'Data de Atualização', minWidth: sizeCol, flex: 1 },
    { field: 'paymentDate', headerName: 'Data de Pagamento', minWidth: sizeCol, flex: 1 },
    { field: 'amount', headerName: 'Valor', minWidth: sizeCol, flex: 1 },
    { field: 'startDate', headerName: 'Data de Início', minWidth: sizeCol, flex: 1 },
    { field: 'endDate', headerName: 'Data de Finalização', minWidth: sizeCol, flex: 1 },
    { field: 'qtdWEXP', headerName: 'Qtd WEXP', minWidth: sizeCol, flex: 1 },
    { field: 'qtdUber', headerName: 'Qtd Uber', minWidth: sizeCol, flex: 1 },
    { field: 'purchaseOrder', headerName: 'Pedido de Compra', minWidth: sizeCol, flex: 1 },
    { field: 'invoiceNumber', headerName: 'Número da Nota', minWidth: sizeCol, flex: 1 },
    {
      field: 'attachments',
      headerName: 'Anexos',
      renderCell: () => (
        <IconButton onClick={() => setShowAttachmentsModal(true)}>
          <AttachFile />
        </IconButton>
      ),
      minWidth: sizeCol,
      align: 'center'
    },
    {
      field: 'reprocess',
      headerName: 'Reprocessar Arquivos',
      renderCell: () => (
        <IconButton onClick={() => setShowModal(true)}>
          <Replay />
        </IconButton>
      ),
      minWidth: sizeCol,
      align: 'center'
    },
    {
      field: 'uploadInvoice',
      headerName: 'Upload NF',
      renderCell: () => (
        <IconButton onClick={() => setShowUploadModal(true)}>
          <CloudUpload />
        </IconButton>
      ),
      minWidth: sizeCol,
      align: 'center'
    }
  ];

  return (
    <Container sx={{ width: '100%', height: '600px' }}>
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchBar search={search} setSearch={setSearch} />
        <ExportMenu data={filteredData} headers={columns.map((col) => col.headerName)} />
      </Box>
      <CustomDataGrid rows={filteredData} columns={columns} loading={loading} />

      {/* Modal de Reprocessamento */}
      <ModalWrapper
        showModal={showModal}
        closeModalAction={() => setShowModal(false)}
        title={'Arquivos serão reprocessados.'}
        img={checked}
      >
        <Box>
          <Button variant="contained" onClick={() => setShowModal(false)}>
            Ok
          </Button>
        </Box>
      </ModalWrapper>

      {/* Modal de Upload */}
      <ModalWrapper
        showModal={showUploadModal}
        closeModalAction={() => setShowUploadModal(false)}
        title={'Upload de Nota Fiscal'}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <input type="file" />
          <Box display="flex" gap={2} justifyContent="end">
            <Button
              variant="outlined"
              onClick={() => setShowUploadModal(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Nota Fiscal salva!");
                setShowUploadModal(false);
              }}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </ModalWrapper>


      {/* Modal de Anexos */}
      <ModalWrapper
        showModal={showAttachmentsModal}
        closeModalAction={() => setShowAttachmentsModal(false)}
        title={'Anexos'}
      >
        <CustomDataGrid
          rows={[
            { id: 1, date: '2024-10-01', file: 'arquivo1.pdf' },
            { id: 2, date: '2024-10-05', file: 'arquivo2.pdf' }
          ]}
          columns={[
            { field: 'date', headerName: 'Data', flex: 1 },
            { field: 'file', headerName: 'Arquivo', flex: 1 },
            {
              field: 'download',
              headerName: 'Download',
              renderCell: (params) => (
                <Button onClick={() => handleDownload(params.row.file)}>
                  <Download /> Baixar
                </Button>
              ),
              flex: 1
            }
          ]}
          loading={false}
        />
      </ModalWrapper>
    </Container>
  );
};

export default InvoiceFilesGrid;
