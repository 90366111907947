import { useState } from 'react';

import { Container, Grid, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Autorenew } from '@mui/icons-material';

import ResponsiveAppBar from '../../../components/NavBar';
import HeaderSelect from '../../../components/HeaderSelect';
import Footer from '../../../components/Footer';
import DriverDiagnosticGrid from '../../../components/grids/DriverDiagnosticGrid';
import DiagnosticProductsGrid from '../../../components/grids/DiagnosticProductsGrid';
import DiagnosticModal from '../../../components/DiagnosticModal';
import DiagnosticAttachmentsGrid from '../../../components/grids/DiagnosticAttachmentsGrid';

const mockContent = [
    {
        diagnosticID: 2,
        companyID: 2,
        company: 'AW CAPITAL CONSULTORIA EMPRESARIAL LTDA 1',
        state: 'SP',
        version: '1',
        isDefault: 1,
        active: 1,
        created: '2019-03-01T20:02:47.9506135',
        updated: '2021-03-10T18:45:19.3599087',
        userID: 'd2d55646-103f-4b0c-8dea-ee15f8dbf230',
        user: 'SILVIO RIBEIRO',
        registration: '4i39841'
    },
    {
        diagnosticID: 3,
        companyID: 2,
        company: 'AW CAPITAL CONSULTORIA EMPRESARIAL LTDA 2',
        state: 'SP',
        version: '2',
        isDefault: 1,
        active: 0,
        created: '2019-04-10T19:08:52.5997276',
        updated: '2021-03-10T18:45:19.3599473',
        userID: 'd2d55646-103f-4b0c-8dea-ee15f8dbf230',
        user: 'SILVIO RIBEIRO',
        registration: '4i39842'
    },
    {
        diagnosticID: 4,
        companyID: 2,
        company: 'AW CAPITAL CONSULTORIA EMPRESARIAL LTDA 3',
        state: 'SP',
        version: '3',
        isDefault: 0,
        active: 1,
        created: '2019-04-10T19:09:28.5319067',
        updated: '2021-03-10T18:45:19.3599604',
        userID: 'd2d55646-103f-4b0c-8dea-ee15f8dbf230',
        user: 'SILVIO RIBEIRO',
        registration: '4i39843'
    },
    {
        diagnosticID: 16,
        companyID: 2,
        company: 'AW CAPITAL CONSULTORIA EMPRESARIAL LTDA 4',
        state: 'SP',
        version: '4',
        isDefault: 0,
        active: 0,
        created: '2019-09-06T17:42:07.9324006',
        updated: '2021-03-10T18:45:19.3599716',
        userID: 'd2d55646-103f-4b0c-8dea-ee15f8dbf230',
        user: 'SILVIO RIBEIRO',
        registration: '4i39844'
    }
];

const mockProducts = [
    {
        diagnosticProductsID: 6,
        productID: 'UberX',
        diagnosticID: 2,
        userID: 'c49daaa0-93e7-4cd4-b832-2e729cbf750e',
        user: 'NATANAEL SILVA',
        created: '2019-03-01T20:08:21.4530545',
        updated: '2019-03-01T20:08:21.4530563',
        percentage: 74,
        fee: 5,
        product: {
            productID: 'UberX',
            providerID: 2,
            categoryID: 2,
            category: null,
            description: 'UberX'
        }
    },
    {
        diagnosticProductsID: 7,
        productID: 'Select',
        diagnosticID: 2,
        userID: 'c49daaa0-93e7-4cd4-b832-2e729cbf750e',
        user: 'NATANAEL SILVA',
        created: '2019-03-01T20:08:21.455015',
        updated: '2019-03-01T20:08:21.4550168',
        percentage: 14.5,
        fee: 5,
        product: {
            productID: 'Select',
            providerID: 2,
            categoryID: 9,
            category: null,
            description: 'Select'
        }
    },
    {
        diagnosticProductsID: 8,
        productID: 'Bag',
        diagnosticID: 2,
        userID: 'c49daaa0-93e7-4cd4-b832-2e729cbf750e',
        user: 'NATANAEL SILVA',
        created: '2019-03-01T20:08:21.4626198',
        updated: '2019-03-01T20:08:21.4626215',
        percentage: 0.4,
        fee: 5,
        product: {
            productID: 'Bag',
            providerID: 2,
            categoryID: 9,
            category: null,
            description: 'Bag'
        }
    },
    {
        diagnosticProductsID: 9,
        productID: 'Black',
        diagnosticID: 2,
        userID: 'c49daaa0-93e7-4cd4-b832-2e729cbf750e',
        user: 'NATANAEL SILVA',
        created: '2019-03-01T20:08:21.4646881',
        updated: '2019-03-01T20:08:21.4646901',
        percentage: 2.8,
        fee: 5,
        product: {
            productID: 'Black',
            providerID: 2,
            categoryID: 4,
            category: null,
            description: 'Black'
        }
    },
    {
        diagnosticProductsID: 10,
        productID: 'regular-taxi',
        diagnosticID: 2,
        userID: 'c49daaa0-93e7-4cd4-b832-2e729cbf750e',
        user: 'NATANAEL SILVA',
        created: '2019-03-01T20:08:21.4667707',
        updated: '2019-03-01T20:08:21.4667725',
        percentage: 8.3,
        fee: 2,
        product: {
            productID: 'regular-taxi',
            providerID: 3,
            categoryID: 5,
            category: null,
            description: 'Táxi Comum'
        }
    }
];

const mockAttachs = [
    {
        diagnosticID: 1,
        name: 'File name',
        user: 'User Name',
        created: '01/01/2021',
        file: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        diagnosticID: 2,
        name: 'File name',
        user: 'User Name',
        created: '02/01/2021',
        file: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        diagnosticID: 3,
        name: 'File name',
        user: 'User Name',
        created: '03/01/2021',
        file: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        diagnosticID: 4,
        name: 'File name',
        user: 'User Name',
        created: '03/01/2021',
        file: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        diagnosticID: 5,
        name: 'File name',
        user: 'User Name',
        created: '03/01/2021',
        file: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    }
];

const DriverDiagnostic = () => {
    const data = mockContent;
    const [filteredData, setFilteredData] = useState(data);

    const companies = [...new Set(mockContent.map((item) => item.company))];

    const [selectedCompany, setSelectedCompany] = useState({});
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showAttachsModal, setShowAttachsModal] = useState(false);

    const filterData = (company) => {
        let newData = data;

        if (company !== 'all') {
            newData = newData.filter((item) => item.company === company);
        }

        setFilteredData(newData);
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        py={1}
                        sx={{
                            marginBottom: '10px',
                            borderBottom: '1px solid black'
                        }}
                    >
                        <h2>Diagnóstico</h2>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'end'
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 5 }}>
                                <HeaderSelect
                                    label="Empresas"
                                    defaultValue="all"
                                    onChange={(event) => {
                                        filterData(event.target.value);
                                    }}
                                    menuItems={[
                                        { label: 'Todas', value: 'all' },
                                        ...companies.map((company) => ({
                                            label: company,
                                            value: company
                                        }))
                                    ]}
                                />
                            </Box>
                            <Box display={'flex'} gap={2}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        console.log('Gerar Diagnóstico')
                                    }
                                >
                                    <AddIcon />
                                    Gerar Diagnóstico
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        console.log('Reprocessar Diagnóstico')
                                    }
                                >
                                    <Autorenew />
                                    Reprocessar Diagnóstico
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <DriverDiagnosticGrid
                    data={filteredData}
                    setSelectedCompany={setSelectedCompany}
                    setShowProductsModal={setShowProductsModal}
                    setShowAttachsModal={setShowAttachsModal}
                />
                <Footer />

                <DiagnosticModal
                    showModal={showProductsModal}
                    setShowModal={setShowProductsModal}
                >
                    <DiagnosticProductsGrid products={mockProducts} />
                </DiagnosticModal>

                <DiagnosticModal
                    showModal={showAttachsModal}
                    setShowModal={setShowAttachsModal}
                >
                    <DiagnosticAttachmentsGrid attachments={mockAttachs} />
                </DiagnosticModal>
            </Container>
        </>
    );
};

export default DriverDiagnostic;
