// Phone
export const brPhoneMask = '(99) 99999-9999';
export const usPhoneMask = '999 999 9999';
export const phoneMask = '+99 (99) 99999-9999';

// Location
export const cepMask = '99999-999';

// Document
export const cpfMask = '999.999.999-99';
export const cnpjMask = '99.999.999/9999-99';
export const ieMask = '999.999.999.999';

// DateTime
export const dateMask = '99/99/9999';
export const timeMask = '99:99';
export const dateTimeMask = '99/99/9999 99:99';

// Number
export const percentMask = '99,99%';
export const float2pointMask = '99,99';
