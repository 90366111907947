import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import ExpenSupportGrid from '../../../components/grids/ExpenSupportGrid';

const mockData = [
  {
    wexpID: 'W001',
    registration: '12345',
    name: 'João Silva',
    email: 'joao.silva@empresa.com',
    status: 'Ativo',
    phone: '(11) 99999-9999',
    costCenter: 'Financeiro',
    company: 'WEXP SOLUÇÕES DIGITAIS LTDA',
    date: '2024-09-01',
    reportDetails: 'Relatório Aprovado',
    expenseDetails: 'Despesa Pendente',
    kmHistory: 'Histórico Completo'
  },
  {
    wexpID: 'W002',
    registration: '67890',
    name: 'Maria Souza',
    email: 'maria.souza@empresa.com',
    status: 'Inativo',
    phone: '(11) 98888-8888',
    costCenter: 'RH',
    company: 'AW CAPITAL CONSULTORIA EMPRESARIAL LTDA',
    date: '2024-08-21',
    reportDetails: 'Relatório Pendente',
    expenseDetails: 'Despesa Aprovada',
    kmHistory: 'Histórico Incompleto'
  },
];

const convertData = (data) => {
  return {
    wexpID: data.wexpID,
    registration: data.registration,
    name: data.name,
    email: data.email,
    status: data.status,
    phone: data.phone,
    costCenter: data.costCenter,
    company: data.company,
    date: data.date
  };
};

const ExpenSupport = () => {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    const convertedData = mockData.map(convertData);
    setData(convertedData);
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Suporte Expen/KM</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
            </Box>
          </Grid>
        </Grid>
        <ExpenSupportGrid data={data} />
      </Container>
    </>
  );
};

export default ExpenSupport;
