import { Box, Container, Grid, Button, Tooltip, TextField, Checkbox, Select, MenuItem, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import InputWithMask from '../../InputWithMask';
import { cnpjMask } from '../../../utils/inputMasks';

import img from '../../../assets/images/img.svg';
import info from '../../../assets/images/info.svg';

const CompanyInfoForm = ({data, setShowCancelModal,setShowConfirmModal}) => {
    
    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
        reset
    } = useForm({
        defaultValues: {
            logo: data?.logo || img,
            legalName: data?.legalName || '',
            fantasyName: data?.fantasyName || '',
            cnpj: data?.cnpj || '',
            street: data?.street || '',
            number: data?.number || '',
            complement: data?.complement || '',
            neighborhood: data?.neighborhood || '',
            city: data?.city || '',
            state: data?.state || '',
            project: data?.project || false,
            reason: data?.reason || false,
            justification: data?.justification || false,
            driver: data?.driver || false,
            expen: data?.expen || false,
            km: data?.km || false,
            pay: data?.pay || false,
            sso: data?.sso || false,
            ssoRule: data?.ssoRule || '',
            resetPassword: data?.resetPassword || 90,
        },
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    const onClickSubmit = handleSubmit(onSubmit);

    return (
        <Container>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={2} sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '10px', padding: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Adicionar Empresa</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="logo"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <h4>Logo</h4>
                                    <Box display={'flex'} gap={2} alignItems={'center'}>
                                        <Box sx={{padding:'10px',width:70, height:70, boxShadow: '0px 0px 4px 0px #00000040', borderRadius:'50%'}}>
                                            <img style={{borderRadius:'50%'}} src={field.value} alt="logo" width={70} />
                                        </Box>
                                        <Button variant='contained' sx={{height:36}}>
                                            Adicionar logo
                                        </Button>
                                        <Tooltip title="Tamanhos aceitos: mínimo 100x100, máximo 1200x1200" placement='right'>
                                            <img src={info} alt="info" width={20} />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="legalName"
                            control={control}
                            rules={{ required: 'Nome é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="legalName">Nome</label>
                                    <TextField id='legalName' variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.legalName)} />
                                    {errors?.legalName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.legalName.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="fantasyName"
                            control={control}
                            rules={{ required: 'Nome fantasia é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="fantasyName">Nome fantasia</label>
                                    <TextField id="fantasyName" variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.fantasyName)} />
                                    {errors?.fantasyName && (
                                        <Typography variant="caption" color="error">
                                            {errors?.fantasyName.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="cnpj"
                            control={control}
                            rules={{ required: 'CNPJ é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="cnpj">CNPJ</label>
                                    <InputWithMask
                                        variant="outlined"
                                        placeholder='Digite...'
                                        fullWidth
                                        mask={cnpjMask}
                                        {...field}
                                    />
                                    {errors?.cnpj && (
                                        <Typography variant="caption" color="error">
                                            {errors?.cnpj.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Endereço</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            name="street"
                            control={control}
                            rules={{ required: 'Rua é obrigatória' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="street">Rua</label>
                                    <TextField id="street" variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.street)} />
                                    {errors?.street && (
                                        <Typography variant="caption" color="error">
                                            {errors?.street.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="number"
                            control={control}
                            rules={{ required: 'Número é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="number">Número</label>
                                    <TextField id='number' variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.number)} />
                                    {errors?.number && (
                                        <Typography variant="caption" color="error">
                                            {errors?.number.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name="complement"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="complement">Complemento</label>
                                    <TextField id='complement' variant='outlined' {...field} placeholder='Digite...' fullWidth />
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            name="neighborhood"
                            control={control}
                            rules={{ required: 'Bairro é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="neighborhood">Bairro</label>
                                    <TextField id="neighborhood" variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.neighborhood)} />
                                    {errors?.neighborhood && (
                                        <Typography variant="caption" color="error">
                                            {errors?.neighborhood.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: 'Cidade é obrigatória' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="city">Cidade</label>
                                    <TextField id="city" variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.city)} />
                                    {errors?.city && (
                                        <Typography variant="caption" color="error">
                                            {errors?.city.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name="state"
                            control={control}
                            rules={{ required: 'Estado é obrigatório' }}
                            render={({ field }) => (
                                <Box>
                                    <label htmlFor="state">Estado</label>
                                    <TextField id="state" variant='outlined' {...field} placeholder='Digite...' fullWidth error={Boolean(errors?.state)} />
                                    {errors?.state && (
                                        <Typography variant="caption" color="error">
                                            {errors?.state.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Customizar</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="project"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="project">Projeto</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="project"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="reason"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="reason">Motivo</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="reason"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="justification"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="justification">Justificativa</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="justification"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Typography variant='h6'>Verticais</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="driver"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="driver">Driver</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="driver"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="expen"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="expen">Expen</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="expen"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="km"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="km">Km</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="km"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="pay"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="pay">Pay</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="pay"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Segurança</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name="sso"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="sso">SSO</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="sso"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="ssoRule"
                            control={control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="ssoRule">Regra para senha</label>
                                    <Select
                                        id='ssoRule'
                                        size='small'
                                        value={field.value}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        displayEmpty
                                        renderValue={field.value !== "" ? undefined : () => 'Selecione...'}
                                    >
                                        <MenuItem value={1}>Regra 1</MenuItem>
                                        <MenuItem value={2}>Regra 2</MenuItem>
                                    </Select>
                                    {errors?.ssoRule && (
                                        <Typography variant="caption" color="error">
                                            {errors?.ssoRule.message}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Troca de senha</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="resetPassword"
                            control={control}
                            render={({ field }) => (
                                <Box display={'flex'} gap={2} alignItems={'center'}>
                                    <TextField sx={{width:70}} inputProps={{inputMode:'numeric'}} id='cutoffDateWexpReports' variant='outlined' type="number" {...field}/>
                                    <Typography variant='body2' color={'#767676'}>Selecione a quantidade de dias para a troca de senha</Typography>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>

                <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                    <Button variant='contained' type='submit'>Salvar</Button>
                    <Button variant='outlined' onClick={() => setShowCancelModal(true)}>Cancelar</Button>
                </Box>
            </form>
        </Container>
    );
};

export default CompanyInfoForm;
