// Redux
import { combineReducers } from 'redux';

// TopLevel slices
import EntitiesReducer from './_Entities/index'
import UiReducer from './_UI/index';

const rootReducer = combineReducers({
    entities: EntitiesReducer,
    ui: UiReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
