import React from 'react';
import { Box, Grid } from '@mui/material';
import VoucherInfoCard from './VoucherInfoCard';
import InfoCard from './InfoCard';

const InfoCards = ({ cardsData }) => {
  return (
    <Box sx={{ marginTop: 3, marginBottom: 6, padding: 0 }}>
      <Grid container spacing={2}>
        {/* Usando o VoucherInfoCard específico para o primeiro card */}
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ height: '100%' }}>
            <VoucherInfoCard 
              title="Carga de usuários Uber"
              selectOptions={['WEXP']}
              downloadText="Baixar Arquivo UBER"
              updateText="Atualizar base na Uber"
            />
          </Box>
        </Grid>
        {cardsData.slice(1).map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ height: '100%' }}>
              <InfoCard title={card.title} values={card.values} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default InfoCards;
