import { Button, Box, Modal } from "@mui/material";

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    height: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: "none",
    p: 4,
}

const DiagnosticModal = ({showModal, setShowModal, children }) => {

    return(
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-company" sx={modalContentStyle}>
                <Box height={350} sx={{display:'flex', flexDirection:'column', gap:3}}>
                    {children}
                </Box>

                <Box display={'flex'} justifyContent={'center'}>
                    <Button variant="contained" size="large" onClick={() => setShowModal(false)}>OK</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DiagnosticModal;