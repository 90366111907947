import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';

import ColorButton from '../components/Buttons';
import logo from '../assets/images/logow.png';
import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../store/_Entities/User.ts';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const dispatch = useDispatch();
    const [firstLogin, setFirstLogin] = useState(true);

    const { user } = useSelector((state) => state.entities);

    const onSubmit = async (data) => {
        dispatch(loginUser(data));
        setFirstLogin(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (user.isLoggedIn) {
            navigate('/home');
        }
    }, [user.isLoggedIn]);

    return (
        <div className="login">
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                    md={6}
                    sx={{ minHeight: '50vh' }}
                >
                    <Grid
                        maxWidth={600}
                        sx={{
                            background: 'white',
                            padding: '25px 100px',
                            borderRadius: '10px'
                        }}
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                maxWidth: '400px'
                            }}
                        >
                            <img className="img-login" src={logo} alt="" />
                            <TextField
                                label="Email"
                                className="login-input"
                                variant="standard"
                                fullWidth
                                {...register('email')}
                            />
                            <TextField
                                label="Senha"
                                className="login-input"
                                type="password"
                                variant="standard"
                                {...register('password')}
                                fullWidth
                                margin="normal"
                            />
                            {user.error && !firstLogin && (
                                <p style={{ color: 'red' }}>
                                    Usuário e/ou senha inválidos
                                </p>
                            )}

                            <Link
                                className="link-password-reset"
                                href="https://auth.wexp.com.br/account/ForgotPassword"
                            >
                                Esqueceu sua senha?
                            </Link>

                            <div id="recaptcha" />

                            <ColorButton
                                text={
                                    user.loading ? (
                                        <CircularProgress
                                            color={'inherit'}
                                            size={25}
                                            thickness={5}
                                        />
                                    ) : (
                                        'ENTRAR'
                                    )
                                }
                                type="submit"
                            />
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Login;
