import { useState } from 'react';

import { filterByValue } from '../../utils/filterGrid';

import { Container, Box, Button, Checkbox } from '@mui/material';

import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';
import CustomDataGrid from './components/CustomDataGrid';
import { ModalWrapper } from '../Modal';

import checked from '../../assets/images/checked.png';
import cancel from '../../assets/images/cancel.png';
import alerta from '../../assets/images/alerta.png';

const CompanyAdminGrid = ({
    data,
    deleteHandler,
    showDeleteModal,
    setShowDeleteModal,
    loading
}) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({
        title: '',
        show: false
    });
    const sizeCol = 160;

    const transformData = (data) => {
        return data.map((item) => {
            return {
                companyAdministratorID: item.companyAdministratorID,
                userID: item.userID,
                name: item.user.name,
                email: item.user.email,
                uberADM: item.uberADM
            };
        });
    };

    const transformedData = transformData(data);

    const filteredData = filterByValue(transformedData, search);
    const [selectedUser, setSelectedUser] = useState([]);

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    };

    const gridProps = {
        disableSelectionOnClick: true,
        getRowId: (row) => row.userID,
        loading: loading
    };

    const columns = [
        {
            field: 'userID',
            headerName: 'Código',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nome',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            headerAlign: 'start',
            align: 'start',
            minWidth: sizeCol / 2,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Deletar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 50,
            flex: 1,
            renderCell: (params) => (
                <Box
                    alignItems={'center'}
                    onClick={() => {
                        setSelectedUser(params.row);
                        setShowDeleteModal(true);
                    }}
                >
                    <img
                        src={cancel}
                        alt="Deletar"
                        style={{ width: '20px', cursor: 'pointer' }}
                    />
                </Box>
            )
        },
        {
            field: 'uberAdm',
            headerName: 'Voucher Uber',
            headerAlign: 'center',
            align: 'center',
            minWidth: 50,
            flex: 1,
            renderCell: (params) => (
                <Checkbox checked={params.row.uberADM === 1 ? true : false} />
            )
        }
    ];

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box
                mb={10}
                sx={{ display: `flex`, justifyContent: 'space-between' }}
            >
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu
                    data={filteredData}
                    showExportModalHandler={showExportModalHandler}
                    headers={{
                        id: 'Código',
                        name: 'Nome',
                        email: 'Email',
                        uberVoucher: 'Voucher Uber'
                    }}
                />
            </Box>

            <CustomDataGrid
                rows={filteredData}
                columns={columns}
                props={gridProps}
            />

            <ModalWrapper
                showModal={showDeleteModal}
                closeModalAction={() => setShowDeleteModal(false)}
                title="Deseja Deletar o usuário?"
                img={alerta}
            >
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box>{selectedUser.name}</Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => deleteHandler(selectedUser)}
                    >
                        Confirmar
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        Cancelar
                    </Button>
                </Box>
            </ModalWrapper>

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(false)}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => setShowExportModal(false)}
                    >
                        Ok
                    </Button>
                </Box>
            </ModalWrapper>
        </Container>
    );
};

export default CompanyAdminGrid;
