import { useState } from "react";
import { Container, Box, Button, IconButton } from "@mui/material";
import SearchBar from "./components/SearchBar";
import ExportMenu from "./components/ExportMenu";
import CustomDataGrid from "./components/CustomDataGrid";
import { ModalWrapper } from "../Modal";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { filterByValue } from "../../utils/filterGrid";
import WarningIcon from '@mui/icons-material/Warning';
import Footer from "../Footer";
import checked from '../../assets/images/checked.png';

const ExpenUserExpensesDetailsGrid = ({ data }) => {
    const [search, setSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState({ title: '', show: false });
    const filteredData = filterByValue(data, search);

    const showExportModalHandler = (title) => {
        setShowExportModal({ title, show: true });
    }

    const gridProps = {
        getRowId: (row) => row.expenseID,
        disableSelectionOnClick: true,
    };

    const columns = [
        {
            field: 'alert',
            headerName: 'Alerta',
            headerAlign: 'center',
            align: 'center',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                params.value === 'Urgente' ? <WarningIcon color="error" /> : null
            ),
        },
        {
            field: 'file',
            headerName: 'Arquivo',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <IconButton href={`/${params.value}`} target="_blank">
                    <AttachFileIcon />
                </IconButton>
            ),
        },
        { field: 'expenseType', headerName: 'Tipo de Despesa', minWidth: 150, flex: 1 },
        { field: 'value', headerName: 'Valor', minWidth: 100, flex: 1 },
        { field: 'currency', headerName: 'Moeda', minWidth: 100, flex: 1 },
        { field: 'date', headerName: 'Data', minWidth: 150, flex: 1 },
        { field: 'description', headerName: 'Descrição', minWidth: 200, flex: 1 },
        { field: 'costCenter', headerName: 'Centro de Custo', minWidth: 150, flex: 1 },
        { field: 'paymentMethod', headerName: 'Meio de Pagamento', minWidth: 150, flex: 1 },
        { field: 'project', headerName: 'Projeto', minWidth: 150, flex: 1 },
        { field: 'reason', headerName: 'Motivo', minWidth: 150, flex: 1 },
        { field: 'justification', headerName: 'Justificativa', minWidth: 200, flex: 1 },
        { field: 'expenseID', headerName: 'ID da Despesa', minWidth: 100, flex: 1 },
    ];

    return (
        <Container sx={{ width: '100%', height: '500px' }}>
            <Box mb={10} sx={{ display: `flex`, justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu 
                    data={filteredData} 
                    showExportModalHandler={showExportModalHandler} 
                    headers={{
                        alert: 'Alerta',
                        file: 'Arquivo',
                        expenseType: 'Tipo de Despesa',
                        value: 'Valor',
                        currency: 'Moeda',
                        date: 'Data',
                        description: 'Descrição',
                        costCenter: 'Centro de Custo',
                        paymentMethod: 'Meio de Pagamento',
                        project: 'Projeto',
                        reason: 'Motivo',
                        justification: 'Justificativa',
                        expenseID: 'ID da Despesa'
                    }} 
                />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} props={gridProps} />
            <Footer />

            <ModalWrapper
                showModal={showExportModal.show}
                closeModalAction={() => setShowExportModal(prev => ({ ...prev, show: false }))}
                title={showExportModal.title}
                img={checked}
            >
                <Box>
                    <Button variant="contained" onClick={() => setShowExportModal(prev => ({ ...prev, show: false }))}>Ok</Button>
                </Box>
            </ModalWrapper>
        </Container>
    );
}

export default ExpenUserExpensesDetailsGrid;
