import React from 'react';
import { Card, CardContent, Typography, Box, Button, Select, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const VoucherInfoCard = ({ title, selectOptions = [], downloadText, updateText }) => (
  <Card sx={{ textAlign: 'center', m: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
      {title && (
        <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
      )}
      {selectOptions.length > 0 && (
        <Select variant="standard" defaultValue={selectOptions[0]} sx={{ marginBottom: 2, padding: 0 }}>
          {selectOptions.map((option, index) => (
            <MenuItem value={option} key={index}>{option}</MenuItem>
          ))}
        </Select>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        {downloadText && (
          <Button variant="text" startIcon={<DownloadIcon />} sx={{ color: 'blue', fontSize: '0.7rem' }}>
            {downloadText}
          </Button>
        )}
        {updateText && (
          <Button variant="contained" color="warning" sx={{fontSize: '0.7rem' }}>
            {updateText}
          </Button>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default VoucherInfoCard;
