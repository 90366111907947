import { useState } from 'react';
import { Container, Box } from '@mui/material';

import SearchBar from './components/SearchBar';
import ExportMenu from './components/ExportMenu';
import CustomDataGrid from './components/CustomDataGrid';
import { filterByValue } from '../../utils/filterGrid';

const InvoicingConfigGrid = ({ data, loading }) => {
    const [search, setSearch] = useState('');
    const sizeCol = 180;

    const filteredData = filterByValue(data, search);

    const columns = [
        { field: 'createdAt', headerName: 'Data de Criação', minWidth: sizeCol, flex: 1 },
        { field: 'userID', headerName: 'ID do Usuário', minWidth: sizeCol, flex: 1 },
        { field: 'username', headerName: 'Fatura', minWidth: sizeCol, flex: 1 },
        { field: 'isActive', headerName: 'Ativo?', minWidth: sizeCol, flex: 1 },
        { field: 'invoiceClosingDay', headerName: 'Dia do Mês de Fechamento', minWidth: sizeCol, flex: 1 },
        { field: 'invoiceDueDays', headerName: 'Dias Úteis para Vencimento', minWidth: sizeCol, flex: 1 },
        { field: 'invoiceSuffix', headerName: 'Sufixo da Fatura', minWidth: sizeCol, flex: 1 },
        { field: 'purchaseOrder', headerName: 'Número do Pedido', minWidth: sizeCol, flex: 1 },
        { field: 'debitNote', headerName: 'Nota de Débito', minWidth: sizeCol, flex: 1 },
        { field: 'csvRides', headerName: 'CSV de Corridas', minWidth: sizeCol, flex: 1 },
        { field: 'csvSavings', headerName: 'CSV de Economia', minWidth: sizeCol, flex: 1 },
        { field: 'csvKmUsers', headerName: 'CSV de Usuários KM', minWidth: sizeCol, flex: 1 },
        { field: 'csvExpenUsers', headerName: 'CSV de Usuários Expen', minWidth: sizeCol, flex: 1 }
    ];

    return (
        <Container sx={{ width: '100%', height: '600px' }}>
            <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar search={search} setSearch={setSearch} />
                <ExportMenu
                    data={filteredData}
                    headers={{
                        createdAt: 'Data de Criação',
                        userID: 'ID do Usuário',
                        username: 'Fatura',
                        isActive: 'Ativo?',
                        invoiceClosingDay: 'Dia do Mês de Fechamento',
                        invoiceDueDays: 'Dias Úteis para Vencimento',
                        invoiceSuffix: 'Sufixo da Fatura',
                        purchaseOrder: 'Número do Pedido',
                        debitNote: 'Nota de Débito',
                        csvRides: 'CSV de Corridas',
                        csvSavings: 'CSV de Economia',
                        csvKmUsers: 'CSV de Usuários KM',
                        csvExpenUsers: 'CSV de Usuários Expen'
                    }}
                />
            </Box>
            <CustomDataGrid rows={filteredData} columns={columns} loading={loading} />
        </Container>
    );
};

export default InvoicingConfigGrid;
