import { useFormContext,Controller } from "react-hook-form";

import { Box, Button, TextField, Typography } from "@mui/material";


const SftpStep3 = () => {

    const {control, formState:{ errors }, trigger, handleSubmit } = useFormContext()

    const onSubmit = async () => {
        const result = await trigger(["userID"]);
        if (result) {
            handleSubmit()
        }
    };



    return(
        <>
            <Box mt={5} minHeight={200} boxShadow={'0px 0px 4px 0px #00000040'} p={5} borderRadius={2} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                <Box>
                    <h3>Adicionar novas configurações</h3>
                </Box>

            <Box>
            <Controller
                        name="userID"
                        control={control}
                        rules={{ required: 'Campo obrigatório' }}
                        render={({ field }) => (
                            <Box  display={'flex'} flexDirection={'column'} gap={2}  maxWidth={400}>
                                <label style={{ display: 'block' }} htmlFor="simple-select">ID Usuário</label>
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="Digite..."
                                    error={Boolean(errors?.userID)}
                                />
                                {errors?.userID && (
                                    <Box>
                                    <Typography variant="caption" color="error">
                                        {errors?.userID.message}
                                    </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                    />
            </Box>

            </Box>
            <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
                <Button variant='outlined'>Cancelar</Button>
                <Button variant='contained' onClick={onSubmit} type="submit">Salvar</Button>
            </Box>
        </>
    )

}

export default SftpStep3;