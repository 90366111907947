import { useForm, Controller } from "react-hook-form";
import { Container, Grid, Typography, Checkbox, TextField, Box, Button } from "@mui/material";

const FeeSettingsForm = ({ data, setShowCancelModal, setShowConfirmModal }) => {
    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            driverEconomy: data?.driverEconomy || { active: false, value: 0 },
            driverTransaction: data?.driverTransaction || { active: false, value: 0 },
            driverUser: data?.driverUser || { active: false, value: 0 },
            driverFixedValue: data?.driverFixedValue || { active: false, value: 0 },
            expenEconomy: data?.expenEconomy || { active: false, value: 0 },
            expenUser: data?.expenUser || { active: false, value: 0 },
            expenFixedValue: data?.expenFixedValue || { active: false, value: 0 },
            kmEconomy: data?.kmEconomy || { active: false, value: 0 },
            kmTransaction: data?.kmTransaction || { active: false, value: 0 },
            kmUser: data?.kmUser || { active: false, value: 0 },
            kmFixedValue: data?.kmFixedValue || { active: false, value: 0 },
        },
    });

    const onSubmit = (data) => {
        console.log(data);
    };

    const onClickSubmit = handleSubmit(onSubmit);

    return (
        <Container>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={2} sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '10px', padding: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Fee de Pagamento</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Driver</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Controller
                                    name="driverEconomy.active"
                                    control={control}
                                    render={({ field }) => (
                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="driverEconomy.active">Economia</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="driverEconomy.active"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                                    )}
                                />
                                <Controller
                                    name="driverEconomy.value"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <label htmlFor="driverEconomy">Valor</label>
                                            <Box>
                                                <TextField
                                                    {...field}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    id="driverEconomy"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    disabled={!watch('driverEconomy.active')}
                                                    placeholder="Digite..."
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Controller
                                    name="driverTransaction.active"
                                    control={control}
                                    render={({ field }) => (
                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="driverTransaction.active">Transação</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="driverTransaction.active"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                                    )}
                                />
                                <Controller
                                    name="driverTransaction.value"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <label htmlFor="driverTransaction">Valor</label>
                                            <Box>
                                                <TextField
                                                    {...field}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    id="driverTransaction"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    disabled={!watch('driverTransaction.active')}
                                                    placeholder="Digite..."
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Controller
                                    name="driverUser.active"
                                    control={control}
                                    render={({ field }) => (
                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                    <label htmlFor="driverUser.active">Usuário</label>
                                    <Box display={'flex'} alignItems={'center'}>
                                    <Checkbox
                                        id="driverUser.active"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                    {field.value ? 'Ativo' : 'Inativo'}
                                    </Box>
                                </Box>
                                    )}
                                />
                                <Controller
                                    name="driverUser.value"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <label htmlFor="driverUser">Valor</label>
                                            <Box>
                                                <TextField
                                                    {...field}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    id="driverUser"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    disabled={!watch('driverUser.active')}
                                                    placeholder="Digite..."
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                />
                        </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Controller
                                    name="driverFixedValue.active"
                                    control={control}
                                    render={({ field }) => (
                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                            <label htmlFor="driverFixedValue.active">Valor Fixo</label>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Checkbox
                                                    id="driverFixedValue.active"
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                                {field.value ? 'Ativo' : 'Inativo'}
                                            </Box>
                                        </Box>
                                    )}
                                />
                                <Controller
                                    name="driverFixedValue.value"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <label htmlFor="driverFixedValue">Valor</label>
                                            <Box>
                                                <TextField
                                                    {...field}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    id="driverFixedValue"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    disabled={!watch('driverFixedValue.active')}
                                                    placeholder="Digite..."
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                />
                            </Box>
                            </Grid>
                        <Grid item xs={12}>
                                <Typography variant="h6">Expen</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={'flex'} alignItems={'center'} gap={2}>
                                <Controller
                                    name="expenEconomy.active"
                                    control={control}
                                    render={({ field }) => (
                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                            <label htmlFor="expenEconomy.active">Economia</label>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Checkbox
                                                    id="expenEconomy.active"
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                                {field.value ? 'Ativo' : 'Inativo'}
                                            </Box>
                                        </Box>
                                    )}
                                />
                                <Controller
                                        name="expenEconomy.value"
                                        control={control}
                                        render={({ field }) => (
                                            <Box>
                                                <label htmlFor="expenEconomy">Valor</label>
                                                <Box>
                                                    <TextField
                                                        {...field}
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        id="expenEconomy"
                                                        variant="outlined"
                                                        type="number"
                                                        size="small"
                                                        disabled={!watch('expenEconomy.active')}
                                                        placeholder="Digite..."
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </Box>
                                </Grid>
                            <Grid item xs={6}>
                                    <Box display={'flex'} alignItems={'center'} gap={2}>
                                        <Controller
                                            name="expenUser.active"
                                            control={control}
                                            render={({ field }) => (
                                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                    <label htmlFor="expenUser.active">Usuário</label>
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <Checkbox
                                                            id="expenUser.active"
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                        {field.value ? 'Ativo' : 'Inativo'}
                                                    </Box>
                                                </Box>
                                            )}
                                        />
                                        <Controller
                                            name="expenUser.value"
                                            control={control}
                                            render={({ field }) => (
                                                <Box>
                                                    <label htmlFor="expenUser">Valor</label>
                                                    <Box>
                                                        <TextField
                                                            {...field}
                                                            inputProps={{ inputMode: 'numeric' }}
                                                            id="expenUser"
                                                            variant="outlined"
                                                            type="number"
                                                            size="small"
                                                            disabled={!watch('expenUser.active')}
                                                            placeholder="Digite..."
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display={'flex'} alignItems={'center'} gap={2}>
                                        <Controller
                                            name="expenFixedValue.active"
                                            control={control}
                                            render={({ field }) => (
                                                <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                    <label htmlFor="expenFixedValue.active">Valor Fixo</label>
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <Checkbox
                                                            id="expenFixedValue.active"
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                        {field.value ? 'Ativo' : 'Inativo'}
                                                    </Box>
                                                </Box>
                                            )}
                                        />
                                        <Controller
                                            name="expenFixedValue.value"
                                            control={control}
                                            render={({ field }) => (
                                                <Box>
                                                    <label htmlFor="expenFixedValue">Valor</label>
                                                    <Box>
                                                        <TextField
                                                            {...field}
                                                            inputProps={{ inputMode: 'numeric' }}
                                                            id="expenFixedValue"
                                                            variant="outlined"
                                                            type="number"
                                                            size="small"
                                                            disabled={!watch('expenFixedValue.active')}
                                                            placeholder="Digite..."
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>    
                            <Grid item xs={12}>
                                <Typography variant="h6">KM</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Controller
                                        name="kmEconomy.active"
                                        control={control}
                                        render={({ field }) => (
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                <label htmlFor="kmEconomy.active">Economia</label>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Checkbox
                                                        id="kmEconomy.active"
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                    />
                                                    {field.value ? 'Ativo' : 'Inativo'}
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    <Controller
                                        name="kmEconomy.value"
                                        control={control}
                                        render={({ field }) => (
                                            <Box>
                                                <label htmlFor="kmEconomy">Valor</label>
                                                <Box>
                                                    <TextField
                                                        {...field}
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        id="kmEconomy"
                                                        variant="outlined"
                                                        type="number"
                                                        size="small"
                                                        disabled={!watch('kmEconomy.active')}
                                                        placeholder="Digite..."
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Controller
                                        name="kmTransaction.active"
                                        control={control}
                                        render={({ field }) => (
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                <label htmlFor="kmTransaction.active">Transação</label>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Checkbox
                                                        id="kmTransaction.active"
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                    />
                                                    {field.value ? 'Ativo' : 'Inativo'}
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    <Controller
                                        name="kmTransaction.value"
                                        control={control}
                                        render={({ field }) => (
                                            <Box>
                                                <label htmlFor="kmTransaction">Valor</label>
                                                <Box>
                                                    <TextField
                                                        {...field}
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        id="kmTransaction"
                                                        variant="outlined"
                                                        type="number"
                                                        size="small"
                                                        disabled={!watch('kmTransaction.active')}
                                                        placeholder="Digite..."
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Controller
                                        name="kmUser.active"
                                        control={control}
                                        render={({ field }) => (
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                <label htmlFor="kmUser.active">Usuário</label>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Checkbox
                                                        id="kmUser.active"
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                    />
                                                    {field.value ? 'Ativo' : 'Inativo'}
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    <Controller
                                        name="kmUser.value"
                                        control={control}
                                        render={({ field }) => (
                                            <Box>
                                                <label htmlFor="kmUser">Valor</label>
                                                <Box>
                                                    <TextField
                                                        {...field}
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        id="kmUser"
                                                        variant="outlined"
                                                        type="number"
                                                        size="small"
                                                        disabled={!watch('kmUser.active')}
                                                        placeholder="Digite..."
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Controller
                                        name="kmFixedValue.active"
                                        control={control}
                                        render={({ field }) => (
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                <label htmlFor="kmFixedValue.active">Valor Fixo</label>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Checkbox
                                                        id="kmFixedValue.active"
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                    />
                                                    {field.value ? 'Ativo' : 'Inativo'}
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    <Controller
                                        name="kmFixedValue.value"
                                        control={control}
                                        render={({ field }) => (
                                            <Box>
                                                <label htmlFor="kmFixedValue">Valor</label>
                                                <Box>
                                                    <TextField
                                                        {...field}
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        id="kmFixedValue"
                                                        variant="outlined"
                                                        type="number"
                                                        size="small"
                                                        disabled={!watch('kmFixedValue.active')}
                                                        placeholder="Digite..."
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                </Box>
                            </Grid>
                                        
                                
                </Grid>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                    <Button variant="contained" type="submit">Salvar</Button>
                    <Button variant="outlined" onClick={() => setShowCancelModal(true)}>Cancelar</Button>
                </Box>
            </form>
        </Container>
    );
};

export default FeeSettingsForm;
